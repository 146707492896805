import React, { ReactNode } from 'react'

interface ISection {
    children: string | JSX.Element | JSX.Element[] 
    title: string,
    subtitle?: string
}

function Section({
    children,
    title,
    subtitle
}: ISection) {
    return (
        <div className='mb-8'>
            <hr className='mb-8'></hr>
            <div className='flex flex-col md:flex-row'>
                <div className='flex flex-col w-72 mr-4'>
                    <div className='text-lg font-medium'>
                        {title}
                    </div>
                    <div className='text-md text-gray-600 mb-4 md:mb-0'>
                        {subtitle}
                    </div>
                </div>
                <div className='flex flex-col flex-1'>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Section