import React, { useState } from 'react'
import OnboardingNavigation from './OnboardingNavigation'
import { useSelector } from 'react-redux'
import { shopSelector } from '../../store/models/shopModel'
import { setAccountActivated } from '../../api/api'
import { showToast } from '../../utils/utilities'
import * as Icon from "react-feather"

interface IWelcomeOnboarding {
    onNext?: () => void,
    onPrevious?: () => void,
    onSkip?: () => void
}

function WelcomeOnboarding({
    onNext,
    onPrevious,
    onSkip,
}: IWelcomeOnboarding) {
    const { selectedShop } = useSelector(shopSelector)

    let features = [
        "Appointment management",
        "Unlimited service listing",
        "From 1 to 10 users",
        "Unique booking link",
    ];

    let upcoming = [
        "Whatsapp Marketing",
        "Customer Management",
        "Marketing Tools",
        "Upfront payments",
        "Analytics Dashboard",
    ];

    const [loading, setLoading] = useState(false)
    const activateAccountNext = async () => {
        setLoading(true);

        await setAccountActivated(selectedShop)
            .then(() => {
                setLoading(false)
                // showToast("Your data has been saved", "success")
                onNext && onNext()
            }).catch((e) => {
                setLoading(false)
                showToast("We couldn't save your data, check your internet and try again", "error", 7000)
            })
    }

    return (
        <div>
            <p className='mt-4 mb-2'>With Wavier, you can effortlessly manage your salon, streamline appointments, and boost customer engagement:</p>

            {features.map((feature: string, index) => {
                return (
                    <div key={index} className="flex items-center text-gray-600 mb-2">
                        <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                            <div className='rounded-full p-1 bg-success-100'>
                                <Icon.Check color='#12b76a' size={12} />
                            </div>
                        </span>{feature}

                    </div>
                )
            })}

            <p className='mt-4 mb-2'>Stay alert for exciting upcoming features:</p>
            {upcoming.map((feature: string, index) => {
                return (
                    <div key={index} className="flex items-center text-gray-600 mb-2">
                        <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                            <div className='rounded-full p-1 bg-success-100'>
                                <Icon.Clock color='#318dde' size={12} />
                            </div>
                        </span>{feature}

                    </div>
                )
            })}

            <p className='mt-4 mb-2'>Start your risk-free 30-day trial today and experience the future of salon management!</p>

            <div className=''>

               
                <OnboardingNavigation
                    canNext={true}
                    canPrevious={true}
                    showNext={true}
                    showPrevious={true}
                    showSkip={false}
                    canSkip={false}
                    onNext={activateAccountNext}
                    onPrevious={onPrevious}
                    onSkip={onSkip}
                    loading={false} />
            </div>

        </div>
    )
}


export default WelcomeOnboarding