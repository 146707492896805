import { features } from 'process'
import React, { useState } from 'react'
import { Button } from './button'
import * as Icon from "react-feather"
import axios from 'axios'

interface IPlanCard {
    plan: string,
    features: string[],
    current: string,
    onUpgrade?: () => void,
    loading?: boolean,
    upgradeLink?: string,
    priceGHS: string,
    priceUSD: string,
    billingInterval: "monthly" | "yearly",
    // billingState: "trialing" | "active" | "inactive" | "non-renew" | "cancelled"
    billingState: any
}

function PlanCard({ plan, features, current, onUpgrade, loading, upgradeLink, priceGHS, priceUSD, billingInterval, billingState }: IPlanCard) {

    const [currency, setCurrency] = useState<"usd" | "ghs">("usd")



    return (
        <div className="w-full max-w-lg">
            <div className="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col justify-between relative overflow-hidden">
                <>
                    <div className='flex justify-between items-start gap-2'>
                        <h1 className="text-3xl flex-1 pr-4 text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none">{plan} <span className='text-base'>{currency === "ghs" ? priceGHS : priceUSD}</span></h1>
                
                            <>
                                <div className={`${currency === "ghs" ? "font-semibold px-2  rounded-lg bg-gray-100 text-gray-900" : "text-gray-500"} cursor-pointer `}
                                    onClick={() => setCurrency("ghs")}>
                                    GHS
                                </div>
                                <div className={`${currency === "usd" ? "font-semibold px-2  rounded-lg bg-gray-100 text-gray-900" : "text-gray-500"} cursor-pointer `}
                                    onClick={() => setCurrency("usd")}>
                                    USD
                                </div>
                            </>
                       
                    </div>
                    {features.map((feature: string, index) => {
                        return (
                            <div key={index} className="flex items-center text-gray-600 mb-2">
                                <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                                    <div className='rounded-full p-1 bg-success-100'>
                                        <Icon.Check color='#12b76a' size={12} />
                                    </div>
                                </span>{feature}

                            </div>
                        )
                    })}
                </>
                <div className='mt-2'>
                    {current?.toLowerCase() === plan?.toLowerCase() && billingState !== "inactive"?
                        <div className='rounded-lg font-semibold border-2 border-gray-300 flex justify-center items-center py-1'>
                            Current plan
                        </div>
                        :
                        <div className=''>
                            <a href={upgradeLink ? upgradeLink : undefined} target="_blank" rel="noreferrer">
                                <Button disabled={false} loading={loading} onClick={async () => {
                                    onUpgrade && onUpgrade()
                                }} size={'sm'}>Upgrade to {plan} - {billingInterval === "monthly" ? "Monthly" : "Yearly"}</Button>
                            </a>
                        </div>}
                </div>
                {/* <p className="text-xs text-gray-500 mt-3">Literally you probably haven't heard of them jean shorts.</p> */}
            </div>
        </div>
    )
}

export default PlanCard