import moment from 'moment';
import React, { useEffect, useState } from 'react'
import axios, { AxiosInstance } from 'axios';
import * as Icon from 'react-feather';
// import AnonymousUserImage from '../../assets/images/anonymous.jpeg'

import { Oval } from 'react-loader-spinner'
import { MessageIcon } from './svgs';
import AppointmentStatusDetails from './AppointmentStatusDetails';
import Select from 'react-select';
import { useIsMobile } from '../hooks/useIsMobile';
import { Button } from './button';
import { firestoreDb } from '../config/firebase.config';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { showToast } from '../utils/utilities';
import { useSelector } from 'react-redux';
import { userSelector } from '../store/models/userModel';
import { canRemoveUser } from '../utils/roles';
import { professionalSelector } from '../store/models/professionalsModel';
import { shopSelector } from '../store/models/shopModel';
import { Tooltip } from 'react-tooltip';

interface IProfessionalDetails {
    selectedPro: any,
    shop: any
    onClose?: () => void,
    onPressRemove?: () => void,
    loadingDetails: boolean
    // onDidntShow: () => void,
    // onAccept: () => void,
    // onReject: () => void,
    // onActionError: () => void
}

function ProfessionalDetails({ selectedPro, onClose, shop, loadingDetails, onPressRemove }: IProfessionalDetails) {

    const { isMobile } = useIsMobile()

    const { roles, id } = useSelector(userSelector)
    const { usersData } = useSelector(professionalSelector);
    const { selectedShop } = useSelector(shopSelector);


    const [rolesArray, setRoles] = useState<string[]>([])

    let client: AxiosInstance;

    if (process.env.NODE_ENV === "development") {
        client = axios.create({
            baseURL: "http://127.0.0.1:5001/barbapp-d4159/us-central1/"
        })
    } else {
        client = axios.create({
            baseURL: "https://us-central1-barbapp-d4159.cloudfunctions.net/"
        })
    }

    const [appointmentActionLoading, setAppointmentActionLoading] = useState(false)

    const [madeChanges, setMadeChanges] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        if (selectedPro) {
            console.log(usersData[selectedShop][selectedPro?.id]?.roles);

            setRoles(usersData[selectedShop][selectedPro?.id].roles)
        }
    }, [selectedPro]);

    useEffect(() => {
        console.log(rolesArray);
    }, [rolesArray])

    return (
        <>

            <div className="w-full md:w-[24rem] bg-white h-full rounded-lg p-4 overflow-auto">
                <Tooltip anchorSelect=".help-owner" place="top">
                    Owners have all permissions and can perform every action on the business dashboard
                </Tooltip>
                <Tooltip anchorSelect=".help-admin" place="top">
                    Admins have permission to perform most actions on the business dashboard, with some restrictions such as payment methods.
                </Tooltip>
                <Tooltip anchorSelect=".help-staff" place="top">
                    Staff can perform the services and are the ones the clients will see when booking. This can be barbers, hair dressers etc.
                </Tooltip>
                <div className='flex justify-end cursor-pointer' onClick={onClose}>
                    <Icon.X />
                </div>
                <div className='w-full flex justify-center'>
                    <p className="mb-4 text-lg font-semibold">
                        User details
                    </p>
                </div>

                {loadingDetails ?
                    <>
                        <div className='flex flex-1 grow justify-center '>
                            <Oval color='#343434' secondaryColor='#7D7D7D' width={40} />
                        </div>
                    </> :
                    <>
                        {selectedPro ?
                            <>
                                <div className='flex flex-col items-center justify-between'>
                                    <img
                                        className="object-cover w-16 h-16 rounded-full"
                                        src={
                                            selectedPro.profilePic ? selectedPro.profilePic : "https://firebasestorage.googleapis.com/v0/b/barbapp-d4159.appspot.com/o/appImages%2Fuser%20avatar.png?alt=media&token=761601ec-b7f7-4ea0-a96e-45a025c06a5c"
                                        }
                                        alt="pro_picture"
                                    />
                                    <div className='mt-4 font-semibold text-xl'>
                                        {selectedPro.name}
                                    </div>
                                </div>

                                <div className="flex justify-between bg-gray-50 p-4 rounded-lg my-5 mx-4">
                                    <div className="flex items-center gap-4">
                                        <div>

                                            {isMobile ? <>

                                                <div className='flex gap-2'>
                                                    <div className='my-auto'>
                                                        <Icon.Phone size={14} color="#667085" />
                                                    </div>
                                                    <p className='text-sm text-gray-500'>
                                                        {selectedPro?.phone}
                                                    </p>
                                                </div>



                                                <div className='flex gap-2'>
                                                    <div className='my-auto'>
                                                        <Icon.Mail size={14} color="#667085" />
                                                    </div>
                                                    <p className='text-sm text-gray-500'>
                                                        {selectedPro?.email}
                                                    </p>
                                                </div>

                                            </> :
                                                <>
                                                    <a href={`tel:${selectedPro?.Phone_Number}`}>
                                                        <div className='flex gap-2'>
                                                            <div className='my-auto'>
                                                                <Icon.Phone size={14} color="#667085" />
                                                            </div>
                                                            <p className='text-sm text-gray-500'>
                                                                {selectedPro?.phone}
                                                            </p>
                                                        </div>
                                                    </a>

                                                    <a
                                                        href={`mailto:${selectedPro?.email}`}
                                                    >
                                                        <div className='flex gap-2'>
                                                            <div className='my-auto'>
                                                                <Icon.Mail size={14} color="#667085" />
                                                            </div>
                                                            <p className='text-sm text-gray-500'>
                                                                {selectedPro?.email}
                                                            </p>
                                                        </div>
                                                    </a>
                                                </>}

                                        </div>

                                    </div>

                                </div>

                                <div>
                                    <div className='text-md font-medium mb-2'>
                                        Roles
                                    </div>
                                    <div>
                                        <div className='text-md underline'>
                                            Admin roles
                                        </div>
                                        <p className='text-sm mb-2 text-gray-500'>
                                            They're responsible for the management of the business. They can add users, services and more.
                                            {/* <span><a className='text-xs cursor-pointer hover:underline'>Learn more</a></span> */}
                                        </p>
                                        <div className='flex flex-row items-center'>
                                            <span className='mr-2'>
                                                <input disabled={rolesArray.includes("admin")} type='checkbox' checked={rolesArray.includes("owner")} onChange={(change) => {
                                                    if (change.target.checked) {
                                                        setRoles((prevRoles) => {
                                                            return [...prevRoles, "owner"]
                                                        })
                                                    } else {
                                                        console.log("owner removed");

                                                        const rolesTemp = rolesArray.map(role => role);

                                                        rolesTemp.splice(rolesTemp.indexOf("owner"), 1);

                                                        setRoles(rolesTemp);
                                                    }
                                                    setMadeChanges(true);
                                                }}></input>
                                            </span>
                                            Owner
                                            <span className='help-owner pl-2'><Icon.Info size={16} color='#667085' /></span>

                                        </div>

                                        <div className='flex flex-row items-center'>
                                            <span className='mr-2'>
                                                <input disabled={rolesArray.includes("owner")} checked={rolesArray.includes("admin")} type='checkbox' onChange={(change) => {
                                                    if (change.target.checked) {
                                                        setRoles((prevRoles) => {
                                                            return [...prevRoles, "admin"]
                                                        })

                                                    } else {
                                                        console.log("admin removed");
                                                        const rolesTemp = rolesArray.map(role => role);;

                                                        rolesTemp.splice(rolesTemp.indexOf("admin"), 1);

                                                        setRoles(rolesTemp);
                                                    }

                                                    setMadeChanges(true);
                                                }}></input>
                                            </span>
                                            Admin
                                            <span className='help-admin pl-2'><Icon.Info size={16} color='#667085' /></span>
                                        </div>

                                    </div>

                                    <div className='text-md mt-6 underline'>
                                        Regular roles
                                    </div>
                                    <p className='text-sm mb-2 text-gray-500'>
                                        They have an access limited to their function  in the business
                                        {/* <span><a className=''>Learn more</a></span> */}
                                    </p>
                                    <div className='flex flex-row items-center'>
                                        <span className='mr-2'>
                                            <input type='checkbox' checked={rolesArray.includes("staff")} onChange={(change) => {
                                                if (change.target.checked) {
                                                    setRoles((prevRoles) => {
                                                        return [...prevRoles, "staff"]
                                                    })
                                                } else {
                                                    console.log("staff removed");

                                                    const rolesTemp = rolesArray.map(role => role);

                                                    rolesTemp.splice(rolesTemp.indexOf("staff"), 1);

                                                    setRoles(rolesTemp);
                                                }
                                                setMadeChanges(true);
                                            }}></input>
                                        </span>
                                        Staff
                                        <span className='help-staff pl-2'><Icon.Info size={16} color='#667085' /></span>
                                    </div>

                                </div>

                                <div className='mt-4'>
                                    <Button loading={loading} disabled={!madeChanges || rolesArray.length === 0} onClick={() => {
                                        try {
                                            setLoading(true);

                                            console.log("Saving user changes" , {
                                                businessID: selectedShop,
                                                userID: selectedPro.id,
                                                roles: rolesArray,
                                                adminID: id
                                            });

                                            client.post("/editUserRoles", {
                                                businessID: selectedShop,
                                                userID: selectedPro.id,
                                                roles: rolesArray,
                                                adminID: id
                                            })
                                                .then((res) => {
                                                    showToast("User data saved!", "success")
                                                })
                                                .catch((e) => {
                                                    showToast("Couldn't edit user data. Try again later.", "error")
                                                })
                                                .finally(() => {
                                                    setLoading(false);
                                                })
                                        } catch (e) {

                                        }
                                    }} size={'sm'}>Save changes</Button>
                                </div>

                                <div className='mt-2'>
                                    {canRemoveUser(roles) &&
                                        <Button size='sm' destructive secondary disabled={false} onClick={() => {
                                            onPressRemove && onPressRemove()
                                        }}>Remove from this business</Button>}
                                </div>



                            </> :
                            <>

                            </>


                        }
                    </>}




            </div>

            {/* <div className="w-full md:w-[24rem] bg-white rounded-lg p-4 mt-4">
                <div className='w-full flex justify-center'>
                    <p className="mb-4 text-lg font-semibold">
                        Performance
                    </p>
                </div>


            </div> */}
        </>
    )
}

export default ProfessionalDetails