import { useState, useCallback, forwardRef, useImperativeHandle, useEffect } from 'react'
import Cropper from 'react-easy-crop'
import getCroppedImg from '../utils/cropImage'
import { Button } from './button'

interface IImageCropper {
    image: any,
    aspectRatio?: number,
    uploadButtonText: string,
    onUpload: () => void,
    onCancel: () => void
}

const ImageCropper = ({ image, aspectRatio, uploadButtonText, onUpload, onCancel }: IImageCropper, ref: any) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState<number>(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null)
    const [croppedImage, setCroppedImage] = useState(null)

    const [loading, setLoading] = useState<boolean>(false)

    const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            await getCroppedImg(
                image,
                croppedAreaPixels
            ).then((croppedImage) => {
                // console.log('donee', { croppedImage })
                setLoading(false)
                setCroppedImage(croppedImage)

            })

        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels])

    useEffect(() => {
        if (croppedImage) {
            onUpload()
        }
    }, [croppedImage, onUpload])

    useImperativeHandle(ref, () => {
        return {
            getImageURL: () => croppedImage
        }
    }, [croppedImage]);

    return (
        <div className=''>
            <div className='absolute top-0 left-0 z-40 w-full bottom-[80px]'>
                <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={aspectRatio}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                />

            </div>

            <div className='absolute bottom-0 w-full h-24 left-0 z-40'>
                <div className='bg-white flex-1 flex flex-col items-center justify-center h-full '>
                    <div className='text-sm text-gray-500'>
                        Adjust the image
                    </div>
                    <div className="w-64">
                        <input
                            type="range"
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e) => {
                                setZoom(parseFloat(e.target.value))
                            }}
                            className="w-full"
                        />
                    </div>
                    <div className="flex gap-4">
                        <div className=' w-32 mt-2'>
                            <Button loading={loading} disabled={false} onClick={() => {
                                showCroppedImage()
                                setLoading(true)
                            }} size={'sm'}>{uploadButtonText}</Button>
                        </div>
                        <div className=' w-32 mt-2'>
                            <Button secondaryGray disabled={false} onClick={onCancel} size={'sm'}>Cancel</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(ImageCropper)