import React, { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { canAddService } from '../utils/roles'
import { useSelector } from 'react-redux'
import { userSelector } from '../store/models/userModel'
import * as Icon from "react-feather"
import { useNavigate } from 'react-router-dom'
import { Button } from './button'
import UpgradeButton from './UpgradeButton'
import { shopSelector } from '../store/models/shopModel'

interface IBookingLink {
    servicesCount: number,
    prosCount: number,
    businessID: string
}

function BookingLink({
    servicesCount,
    prosCount,
    businessID
}: IBookingLink) {
    const { roles } = useSelector(userSelector);
    const navigate = useNavigate();

    const { shopsData, selectedShop } = useSelector(shopSelector);
    const [subscription, setSubscription] = useState<any>({})
    useEffect(() => {
        setSubscription(shopsData[selectedShop].settings.subscription);
    }, [shopsData, selectedShop])

    return (
        <div className='w-full'>
            <div className='bg-white mx-2 mt-2 py-2 rounded-lg px-4'>
                <div className='font-medium text-md'>
                    Your link
                </div>
                {(servicesCount > 0 && prosCount > 0) ?
                    <div className='flex md:flex-row flex-col gap-2 mt-4 overflow-auto'>
                        <p className='px-4 py-2 border flex-wrap rounded-lg break-normal text-ellipsis'>
                            {`https://book.wavier.io/business/${businessID}`}
                        </p>

                        <div className="cursor-pointer px-4 py-2 border rounded-lg  justify-center items-center bg-gray-900">
                            <CopyToClipboard
                                text={`https://book.wavier.io/business/${businessID}`}
                                onCopy={() => alert("Copied")}>
                                <span className="flex gap-2 justify-center items-center">
                                    <div className="font-semibold text-white">
                                        Copy
                                    </div>
                                    <div>
                                        <Icon.Copy color="#fff" />
                                    </div>
                                </span>
                            </CopyToClipboard>
                        </div>
                    </div>
                    :
                    <div className='py-2'>
                        <div className='flex md:flex-row flex-col gap-2 mt-4 blur-sm select-none'>
                            <p className='px-4 py-2 border flex-wrap rounded-lg break-normal '>
                                {`https://book.wavier.io/business/${businessID}`}
                            </p>

                            <div className="px-4 py-2 border rounded-lg  justify-center items-center bg-gray-900">

                                <span className="flex gap-2 justify-center items-center">
                                    <div className="font-semibold text-white">
                                        Copy
                                    </div>
                                    <div>
                                        <Icon.Copy color="#fff" />
                                    </div>
                                </span>

                            </div>
                        </div>
                        <div className='text-sm text-error-600 mt-2 mb-2'>

                            {servicesCount === 0 && prosCount === 0 && "  Your link will be available once at least one service is added"}
                            {servicesCount === 0 && prosCount > 0 && " Your link will be available once at least one service is added"}
                            {servicesCount > 0 && prosCount === 0 && " Your link will be available once you add at least one staff member"}
                        </div>
                        {canAddService(roles) &&
                            <>
                                <Button disabled={false} onClick={() => {
                                    // navigate("/app/settings/services?action=add")
                                    { servicesCount === 0 && prosCount === 0 && navigate("/app/settings/services?action=add") }
                                    { servicesCount === 0 && prosCount > 0 && navigate("/app/settings/services?action=add") }
                                    { servicesCount > 0 && prosCount === 0 && navigate("/app/settings/users?action=add") }
                                }} hug size={'sm'}>
                                    {servicesCount === 0 && prosCount === 0 && "Add a service"}
                                    {servicesCount === 0 && prosCount > 0 && "Add a service"}
                                    {servicesCount > 0 && prosCount === 0 && "Add staff"}
                                </Button>
                            </>
                        }
                    </div>

                }
                <div className='text-sm text-gray-600 mt-2'>
                    This is your unique link. You can share it with your customers and start receiving appointments.
                </div>

                {subscription?.billingState === "inactive" &&
                    <div className=' mt-4 space-y-2 py-4'>
                        <p className=" gap-1 inline">
                            {`You're not subscribed to a Wavier plan. Your clients will not be able to book appointements until you upgrade your account.`}
                        </p>
                        <UpgradeButton color='purple'/>
                    </div>
                }

            </div>
        </div>
    )
}

export default BookingLink