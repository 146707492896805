import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { store } from './store/store'
import { Provider } from 'react-redux'
import DatabaseStream from './context/DatabaseStreamContext'
import { PostHogProvider } from 'posthog-js/react'
import posthog from 'posthog-js'
import ConnectivityWrapper from './providers/ConnectivityWrapper'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

posthog.init(
    'phc_4MRagtMgIJgRksvq8nD00BZsQ7t91Y5ejNhGlkWXBVX',
    {
        api_host: 'https://app.posthog.com'
    }
);

root.render(
    <React.StrictMode>
        <PostHogProvider client={posthog}>
            <Provider store={store}>
                <ConnectivityWrapper>
                    <DatabaseStream >
                        <App />
                    </DatabaseStream>
                </ConnectivityWrapper>
            </Provider>
        </PostHogProvider>
    </React.StrictMode>
)
