import axios from 'axios'
import React, { useEffect, useState } from 'react'
import * as Icon from "react-feather"
import { useDispatch, useSelector } from 'react-redux'
import { modalSelector } from '../../store/models/modals'
import { userSelector } from '../../store/models/userModel'
import { analyticsEvents } from '../../utils/Events'
import { Button } from '../button'
import { usePostHog } from 'posthog-js/react'
import { shopSelector } from '../../store/models/shopModel'
import { deleteCategory, fetchCategories } from '../../api/api'
import { showToast } from '../../utils/utilities'
import { canAddCategory, canEditCategory, canEditService, canRemoveCategory } from '../../utils/roles'
import clsx from 'clsx'

function CategoriesModal() {

    enum Page {
        "categories",
        "delete"
    }

    const { roles } = useSelector(userSelector)

    const [pagePart, setPagePart] = useState<Page.categories | Page.delete>(Page.categories)

    const [modal, setModal] = useState(false)
    const { selectedShop, selectedCategory } = useSelector(shopSelector)
    const posthog = usePostHog()

    const { categoriesModal } = useSelector(modalSelector)

    const [loading, setLoading] = useState(false)
    const [categories, setCategories] = useState<any[]>([]);

    const dispatch = useDispatch()

    const toggleModal = () => {
        dispatch.Modals.toggleCategoriesModal()
    }

    const fetchData = async () => {
        const fetchedCategories = await fetchCategories(selectedShop);
        setCategories(fetchedCategories);

        console.log(fetchedCategories);
    }

    useEffect(() => {
        if (selectedShop && categoriesModal) {
            fetchData()
        }
    }, [selectedShop, categoriesModal])

    return (
        <>
            {categoriesModal &&
                <>
                    <div className="absolute w-full h-screen z-40 opacity-50 bg-gray-900  flex justify-center items-center">

                    </div>
                    <div className="absolute  w-full z-50 h-screen backdrop-blur-sm flex justify-center items-center">

                        <div className='gap-2 p-6 w-full max-w-lg flex flex-col justify-start bg-white rounded-lg mx-4 border-2 border-gray-200'>
                            {pagePart === Page.categories &&
                                <>
                                    <div className='w-full flex justify-end'>
                                        <div className="cursor-pointer"
                                            onClick={() => {
                                                toggleModal();
                                                setPagePart(Page.categories);
                                            }}
                                        >
                                            <Icon.X color='#98a2b3' size={16} />
                                        </div>
                                    </div>
                                    <div className='font-semibold text-xl'>
                                        Categories
                                    </div>
                                    <p className='text-gray-600 pb-2'>
                                        Create or edit categories of services
                                    </p>

                                    <div className='w-full space-y-2 overflow-auto max-h-96'>

                                        {categories.map((category: any, index: number) => {
                                            return <>
                                                <div className={clsx(
                                                    'w- p-4 border-gray-200 border-2 rounded-lg transition-all',
                                                    canEditCategory(roles) && "hover:border-gray-900 cursor-pointer"
                                                )}
                                                    onClick={() => {
                                                        if (canEditCategory(roles)) {
                                                            console.log(index)
                                                            dispatch.Shop.setSelectedCategory(categories[index])

                                                            toggleModal();
                                                            dispatch.Modals.toggleEditCategoryModal();
                                                        }
                                                    }}>
                                                    <div className='flex flex-row justify-between items-center '>
                                                        <div>
                                                            <div className='text-lg font-semibold'>{category.name}</div>
                                                            {category.description &&
                                                                <div className=''>{category.description}</div>
                                                            }
                                                        </div>
                                                        {canRemoveCategory(roles) &&
                                                            <div className='w-10 opacity-50 hover:opacity-100 cursor-pointer' onClick={(event) => {
                                                                event.stopPropagation();
                                                                dispatch.Shop.setSelectedCategory(categories[index])
                                                                setPagePart(Page.delete);
                                                                setLoading(false);
                                                            }}>
                                                                <Icon.Trash2 width={16} color="#ef4444" />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        })}

                                    </div>
                                    <div className='flex flex-col gap-2 mt-2'>

                                        {canAddCategory(roles) &&
                                            <Button disabled={false} loading={loading} onClick={async () => {
                                                setLoading(false);
                                                toggleModal();
                                                dispatch.Modals.toggleNewCategoryModal();
                                            }} size={'sm'}>
                                                Add a category
                                            </Button>
                                        }

                                        <Button secondaryGray disabled={false} onClick={toggleModal} size={'sm'}>
                                            Close
                                        </Button>
                                    </div>
                                </>}

                            {pagePart === Page.delete &&
                                <>
                                    <div className='w-full flex justify-end'>
                                        <div className="cursor-pointer"
                                            onClick={() => {
                                                toggleModal();
                                                setPagePart(Page.categories);
                                            }}
                                        >
                                            <Icon.X color='#98a2b3' size={16} />
                                        </div>
                                    </div>
                                    <div className='font-semibold text-xl pb-2 text-error-600'>
                                        Delete category
                                    </div>
                                    <div className='pb-2'>

                                        <div className=''>Deleting a category will mark all of its services as "Uncategorized"</div>
                                        <div className=''>You can re-assign those services to a different category later.</div>

                                    </div>

                                    <div className='w-full space-y-2 overflow-auto max-h-96'>
                                        <div className='w- p-4 border-gray-200 border-2 border-error-600 rounded-lg transition-all'
                                        >
                                            <div className='flex flex-row justify-between items-center '>
                                                <div>
                                                    <div className='text-lg font-semibold'>{selectedCategory.name}</div>
                                                    {selectedCategory.description &&
                                                        <div className=''>{selectedCategory.description}</div>
                                                    }
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                    <div className='flex flex-col gap-2 mt-2'>

                                        <Button disabled={false} destructive loading={loading} onClick={async () => {
                                            try {
                                                setLoading(true)
                                                deleteCategory(selectedShop, selectedCategory.id)
                                                setPagePart(Page.categories);
                                                toggleModal();
                                                showToast(selectedCategory.name + " deleted", "success");
                                            } catch (e) {
                                                setLoading(false)
                                                showToast("Couldn't delete category. Please try again.", "error");
                                            }

                                        }} size={'sm'}>
                                            Delete
                                        </Button>

                                        <Button secondaryGray disabled={false} onClick={() => {
                                            setLoading(false)
                                            setPagePart(Page.categories);
                                        }} size={'sm'}>
                                            Cancel
                                        </Button>
                                    </div>
                                </>}
                        </div>
                    </div>
                </>
            }

        </>
    )
}

export default CategoriesModal