import { useRouteError } from 'react-router-dom'
import * as Icon from "react-feather"
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from '../store/models/userModel'
import { auth } from '../config/firebase.config'
import { analyticsEvents } from '../utils/Events'
import { usePostHog } from 'posthog-js/react'

const ErrorPage = () => {
    const error = useRouteError()
    console.error(error)
    const posthog = usePostHog()
    const { email } = useSelector(userSelector)
    const dispatch = useDispatch()

    const logout = () => {
        auth.signOut()
       dispatch.User.saveUser({})

        analyticsEvents.logout();
        posthog.capture("Logout")
    }

    return (
        <div className="flex flex-col h-screen items-center justify-center">
            <div className='border-8 w-16 h-16 flex justify-center items-center bg-warning-100 border-warning-50 rounded-full mb-5'>
                <Icon.Frown size={30} color={"#7a2e0e"} />
            </div>
            <p className='font-semibold'>Sorry, an unexpected error has occurred.</p>
            <p className='text-center'>
                But it's alright, simply check your internet connection and reload this page again.
            </p>

            <div className='text-sm text-gray-500 my-4 px-4 md:px-32'>
                <p>
                    If this error persists, please contact us and provide the following error message:
                </p>
                <p>
                    <i>{(error as any).statusText || (error as any).message}</i>
                </p>
            </div>

            <div className='flex flex-col justify-center items-center'>
                <p className='text-sm cursor-pointer text-gray-700'>{`You're currently signed in as ${email}`}</p>
                <p className='text-sm cursor-pointer text-gray-500 hover:text-gray-900 hover:underline'
                onClick={logout}
                >Sign out</p>
            </div>
        </div>
    )
}

export default ErrorPage
