import React from 'react'
import { Button } from '../button';
import clsx from 'clsx';

interface IOnboardingNavigation {
    canNext: boolean,
    canPrevious: boolean,
    canSkip: boolean,
    showNext: boolean,
    showPrevious: boolean,
    showSkip: boolean,
    onNext?: () => void,
    onPrevious?: () => void,
    onSkip?: () => void,
    loading: boolean,
    nextText?: string
}

function OnboardingNavigation({
    onNext,
    onPrevious,
    onSkip,
    canNext,
    canPrevious,
    canSkip,
    showNext,
    showPrevious,
    showSkip,
    loading,
    nextText
}: IOnboardingNavigation) {
    return (
        <div className={clsx(
            'w-full flex flex-row items-center pt-4 gap-2',
            showPrevious && (showNext || showSkip) && "justify-start md:justify-between",
            !showPrevious && (showNext || showSkip) && "md:justify-end justify-start",
        )}>
            {
                showPrevious &&
                <div className=''>
                    <Button disabled={!canPrevious} hug secondaryGray onClick={() => {
                        onPrevious && onPrevious();
                    }} size={'sm'}>Previous</Button>
                </div>
            }

            <div className='flex flex-row gap-2'>
                {
                    showSkip &&
                    <div>
                        <Button disabled={!canSkip} hug secondaryGray onClick={() => {
                            onSkip && onSkip();
                        }} size={'sm'}>Skip</Button>
                    </div>
                }

                {
                    showNext &&
                    <div>
                        <Button loading={loading} disabled={!canNext} hug onClick={() => {
                            onNext && onNext();
                        }} size={'sm'}>{nextText? nextText : "Next"}</Button>
                    </div>
                }
            </div>
        </div>
    )
}

export default OnboardingNavigation