import { useSelector } from 'react-redux'
import { AppointmentSelector } from '../../store/models/appointmentsModel'
import AppointmentsPage from '../../components/AppointmentsPage'

enum PageParts {
  SIDEBAR = 'sidebar',
  BOOKINGS = 'bookings',
  APPOINTMENT_DETAILS = 'appointments',
}


const HistoryPage = () => {

  const { history }: any = useSelector(AppointmentSelector)
    return (
        <AppointmentsPage title='History' data={history} listTitle={'History of past appointments'} type={'history'} />
    )
  
}

export default HistoryPage
