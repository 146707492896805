import React, { useCallback, useEffect, useRef, useState } from 'react'
import * as Icon from 'react-feather';
import { Oval } from 'react-loader-spinner'
import Creatable from 'react-select/creatable'
import { Button } from './button';
import { showToast, uploadFile } from '../utils/utilities';
import { useDispatch, useSelector } from 'react-redux';
import TextInput from './input/TextInput';
import { addShopService } from '../utils/ShopServicesTasks';
import { shopSelector } from '../store/models/shopModel';
import Compressor from 'compressorjs'
import ImageViewer from 'react-simple-image-viewer';
import { professionalSelector } from '../store/models/professionalsModel';
import { Service } from '../types';
import { saveCategory } from '../api/api';
import clsx from 'clsx';

interface IServiceDetails {
    onClose?: () => void,
    onAddService?: () => void,
    loadingDetails: boolean,
    isOnboarding: boolean
}

function AddService({ onClose, loadingDetails, onAddService, isOnboarding }: IServiceDetails) {


    const { selectedShop, shopsData, categories, latestCategory } = useSelector(shopSelector)

    const dispatch = useDispatch();

    const [categorySelector, setCategorySelector] = useState<any>(null)
    const [loading, setLoading] = useState(false)

    const [pictures, setPictures] = useState<any[]>([])
    const [compressedPictures, setCompressedPictures] = useState<any[]>([])

    const nameRef = useRef<any>()
    const priceRef = useRef<any>()
    const hoursRef = useRef<any>()
    const minutesRef = useRef<any>()
    const descriptionRef = useRef<any>()

    const [name, setName] = useState<string>("")
    const [price, setPrice] = useState<number>(100)

    const [hours, setHours] = useState<number>(1)
    const [minutes, setMins] = useState<number>(0)
    const [description, setDescription] = useState<string>("")

    const { proNames, proIDs } = useSelector(professionalSelector)
    const [selectedPros, setSelectedPros] = useState<any>([])

    useEffect(() => {
        priceRef.current.setValue(100)
        hoursRef.current.setValue(1)
        minutesRef.current.setValue(0)
        setSelectedPros([proIDs[selectedShop][0]]);
    }, [])

    const [hasSelectedCategory, setHasSelectedCategory] = useState<boolean>(false)

    // when user adds category from modal, make sure it sets the validator for category to true
    useEffect(() => {
        if (latestCategory) {
            setHasSelectedCategory(true);
        }
    }, [latestCategory])

    useEffect(() => {
        // console.log(pictures)
        const compressedPicturesTemp: any[] = []

        const generateImageURL = async () => {
            pictures.map(async (pic) => {
                let blob = await fetch(pic).then(r => r.blob());
                new Compressor(blob, {
                    quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
                    convertTypes: ['image/png', 'image/jpeg'],
                    convertSize: 1000000,
                    success: async (compressedResult) => {
                        compressedPicturesTemp.push(compressedResult)
                    },
                });
            })
        }
        if (pictures) {
            generateImageURL()
            // console.log("done compressing", compressedPicturesTemp)
            setCompressedPictures(compressedPicturesTemp)
        }
    }, [pictures])

    const addService = async () => {
        const picturesPromises = compressedPictures.map(async (compressed, index) => {
            // console.log("URL", `shops/${selectedShop}/services/${(categorySelector.value as string).toLowerCase()}_${(nameRef.current.getInputText() as string).toLowerCase()}_${index + 1}`)
            const picURL = uploadFile(compressed, `shops/${selectedShop}/services/${((categorySelector.value as string).toLowerCase()).replace(" ", "_")}_${(nameRef.current.getInputText() as string).toLowerCase()}_${index + 1}`)

            if (picURL) {
                return picURL;
            } else {
                // throw new Error('Failed to upload picture');
            }
        })

        try {
            await Promise.all(picturesPromises)
                .then(async (uploadedPicsURLs) => {
                    const service: Service = {
                        description: descriptionRef.current?.getInputText() ? descriptionRef.current?.getInputText() : "",
                        images: uploadedPicsURLs,
                        name: nameRef.current.getInputText(),
                        price: parseFloat(priceRef.current.getInputText()),
                        duration: `${Math.floor(hours) === 0 || Number.isNaN(Math.floor(hours)) ? "" : `${Math.floor(hours)}h`}${minutes === 0 || Number.isNaN(Math.floor(minutes)) ? "" : `${minutes}mins`}`,
                        duration_minutes: (parseInt(hoursRef.current.getInputText() ? hoursRef.current.getInputText() : 0) * 60) + parseInt(minutesRef.current.getInputText() ? minutesRef.current.getInputText() : 0),
                        professionals: selectedPros ? selectedPros : []
                    }

                    // console.log("new service", service)

                    await addShopService({ queryKey: ["shopID", selectedShop] }, service, categorySelector.value)
                        .then(() => {
                            showToast("Service added", "success")
                            onAddService && onAddService()
                        })
                        .catch(() => {
                            showToast("Couldn't add service, check your internet connection and try again", "error", 7000)
                        })
                        .finally(() => {
                            setLoading(false)
                        })
                })


        } catch (e) {
            showToast("Couldn't add service, check your internet connection and try again", "error", 7000);
            console.log("Couldn't add service. Error:", e);
            setLoading(false);
        }



    }

    useEffect(() => {
        console.log(hours);
    }, [hours])

    // Image viewer stuff

    const [currentImage, setCurrentImage] = useState(0);

    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback((index: number) => {
        setCurrentImage(index);
        // setCurrentDescription(index)
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const [valid, setValid] = useState<any>();
    const validate = () => {
        return name.length && price.toString().length && hours.toString().length && minutes.toString().length
            && hasSelectedCategory && selectedPros.length;
    };
    useEffect(() => {
        const isValid = validate();
        setValid(isValid);
        // console.log({
        //     name: name.length,
        //     price: price.toString().length,
        //     hours: hours.toString().length,
        //     minutes:minutes.toString().length,
        //     cat: selectedCategory
        // }, isValid)
    }, [name, description, price, hours, minutes, hasSelectedCategory, selectedPros]);


    useEffect(() => {
        if (latestCategory) {
            setCategorySelector({
                label: latestCategory?.name,
                value: latestCategory?.id,

            })
        }
    }, [latestCategory, categories]);

    return (
        <>

            <div className={clsx(
                "w-full md:w-[24rem] h-full bg-white rounded-lg p-4 overflow-auto",
                isOnboarding && "border-2 border-gray-200"
            )}>

                <>
                    <div className='flex justify-end cursor-pointer' onClick={onClose}>
                        <Icon.X />
                    </div>
                    <div className='w-full flex justify-center'>
                        <p className="mb-4 text-lg font-semibold">
                            Add a service
                        </p>
                    </div>
                </>


                {loadingDetails ?
                    <>
                        <div className='flex flex-1 grow justify-center '>
                            <Oval color='#343434' secondaryColor='#7D7D7D' width={40} />
                        </div>
                    </> :
                    <>

                        <>


                            <div>
                                <div className='text-lg font-medium mb-2'>
                                    Service
                                </div>

                                <div className='text-sm font-medium'>
                                    Category*
                                </div>
                                {
                                    !isOnboarding &&
                                    <p className='text-sm text-gray-600 mb-2'>
                                        Select from an existing category, or type in the name of a new category to create it.
                                    </p>
                                }

                                <Creatable isClearable placeholder="Type to create a new category" options={categories?.map((category: any) => {
                                    return {
                                        label: category.name,
                                        value: category.id
                                    }
                                })} value={categorySelector} onChange={(change) => {

                                    console.log(change)
                                    if (change !== null) {
                                        setHasSelectedCategory(true)
                                    } else {
                                        setHasSelectedCategory(false)
                                    }
                                    setCategorySelector(change)
                                }} onCreateOption={async (newOption) => {
                                    setHasSelectedCategory(true)

                                    const newAppointmentID = await saveCategory(selectedShop, newOption, "");

                                    setCategorySelector({
                                        label: newOption,
                                        value: newAppointmentID
                                    })

                                    dispatch.Shop.addCategory({
                                        id: newAppointmentID,
                                        name: newOption,
                                        description: "",
                                    })

                                    dispatch.Shop.setLatestCategory({
                                        id: newAppointmentID,
                                        name: newOption,
                                        description: "",
                                    })
                                }}

                                />
                                <div className="flex flex-row gap-2 py-2">
                                    {/* <Icon.PlusCircle /> */}
                                    <div className="font-semibold text-gray-500 text-sm cursor-pointer hover:text-gray-700" onClick={() => {
                                        dispatch.Modals.toggleNewCategoryModal(true);
                                    }}>
                                        Create a category
                                    </div>
                                </div>
                            </div>
                            <div className='mt-4'>
                                <TextInput onChange={() => {

                                    setName(nameRef.current.getInputText())
                                }} ref={nameRef} label='Service name*' placeholder="ie: Regular Haircut, Braids"></TextInput>
                            </div>
                            {
                                !isOnboarding &&
                                <div className='mt-4'>
                                    <TextInput area onChange={() => {

                                        setDescription(descriptionRef.current.getInputText())
                                    }} ref={descriptionRef} label='Description' placeholder='Write your description here...'></TextInput>

                                </div>
                            }
                            <div className='mt-4'>
                                <label className='text-sm font-medium mb-2'>{"Price*"}</label>
                                <div className='flex gap-2 items-center'>
                                    <span className="text-gray-500 text-md ">{shopsData[selectedShop]?.currency}</span>
                                    <TextInput type={"number"} min={0} onChange={() => {

                                        setPrice(priceRef.current.getInputText())
                                    }} ref={priceRef} value={price} placeholder={`i.e: ${shopsData[selectedShop]?.currency} 100`}></TextInput>
                                </div>
                            </div>
                            <div className='mt-4'>
                                <div className='text-sm font-medium mb-2'>
                                    Duration*
                                </div>
                                <div className='flex w-full items-center justify-between'>
                                    <div className='flex items-center gap-2'>
                                        <div className='w-full'>
                                            <TextInput type={"number"} placeholder='Hours' value={hours} min={0} max={24} inputSize='sm' onChange={() => {

                                                setHours(hoursRef.current.getInputText())
                                            }} ref={hoursRef} ></TextInput>
                                        </div>
                                        <div>
                                            hour(s)
                                        </div>
                                    </div>
                                    <div className='flex items-center gap-2'>
                                        <div>
                                            <TextInput required type={"number"} value={minutes} min={0} max={59} placeholder='Minutes' inputSize='sm' onChange={() => {

                                                setMins(minutesRef.current.getInputText())
                                            }} ref={minutesRef} ></TextInput>
                                        </div>
                                        <div>
                                            minutes
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!isOnboarding &&
                                <div className='mt-4'>
                                    <div className='text-lg font-medium mb-2'>Staff</div>
                                    <div className='flex gap-3 flex-wrap'>


                                        <div className='text-xs text-gray-500'>
                                            Select the staff that provide this service
                                        </div>
                                        {proNames?.length === 0 ?
                                            <>
                                                <div className='flex w-full justify-center'>
                                                    <Oval color='#343434' secondaryColor='#7D7D7D' width={40} />
                                                </div>
                                            </>
                                            :
                                            <>

                                                <div className='flex gap-2 flex-wrap w-full'>
                                                    {proNames[selectedShop]?.map((pro: any, index: number) => {
                                                        return (
                                                            <div key={index}>
                                                                <div className={`transition px-4 py-2 border rounded-lg flex items-center justify-center text-center text-sm cursor-pointer border-gray-100 ${selectedPros?.includes(proIDs[selectedShop as any][index]) ? "border border-2 border-gray-900" : "bg-white"}`}
                                                                    onClick={() => {


                                                                        const proID = proIDs[selectedShop as any][index];

                                                                        if (!selectedPros.includes(proID)) {
                                                                            // If the professional ID is not already selected, add it to the array
                                                                            setSelectedPros([...selectedPros, proID])
                                                                            // console.log([...selectedPros, proID])
                                                                        } else {
                                                                            // If the professional ID is already selected, remove it from the array
                                                                            setSelectedPros(selectedPros.filter((id: string) => id !== proID));
                                                                            // console.log(selectedPros.filter((id: string) => id !== proID));
                                                                        }
                                                                    }}>
                                                                    {pro}
                                                                </div>
                                                            </div>)
                                                    })}
                                                </div>
                                            </>}

                                    </div>

                                </div>
                            }
                            {!isOnboarding &&
                                <div className='mt-4'>
                                    <div className='text-lg font-medium mb-2'>Pictures</div>
                                    <div className='flex gap-2 flex-wrap'>
                                        {pictures?.map((image: any, index: number) => {
                                            return (
                                                <div className='relative'>
                                                    <div className='absolute flex justify-center items-center border border-gray-100 drop-shadow-sm cursor-pointer -right-2 -top-2 w-6 h-6 bg-white rounded-full'
                                                        onClick={() => {
                                                            setPictures(pics => pics.slice(0, index).concat(pics.slice(index + 1)))
                                                        }}
                                                    >
                                                        <Icon.X size={16} />
                                                    </div>
                                                    <img key={index} className='w-24 h-24 rounded-lg object-cover object-center cursor-pointer hover:border hover:border-gray-300' src={image} alt="service" onClick={() => {
                                                        openImageViewer(index)
                                                    }}></img>
                                                </div>
                                                // <img key={index} className='w-24 h-24 rounded-lg object-cover object-center' src={image} alt="service"></img>
                                            )
                                        })}
                                        <div className='w-24 h-24 rounded-lg object-cover object-center cursor-pointer border border-gray-300 flex flex-col justify-center items-center'>
                                            <label htmlFor="dropzone-file" className='flex flex-col justify-center items-center cursor-pointer w-full h-full'>
                                                <Icon.PlusCircle color={"#475467"} size={20} />
                                                <div className='text-gray-600 text-sm'>
                                                    Add image
                                                </div>
                                            </label>
                                            <input id="dropzone-file" type="file" className="hidden"
                                                onChange={(event) => {
                                                    const pics: any[] = [];
                                                    if (event) {
                                                        if (!event.target.files) return;
                                                        Array.from(event.target.files).forEach((file) => {
                                                            pics.push(URL.createObjectURL(file))
                                                        })

                                                        // console.log(pics)
                                                        setPictures(prevPictures => [...prevPictures, ...pics])
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/* <input title=' ' multiple id='pics' type="file" className='py-4 block w-full text-sm text-slate-500 text-opacity-0 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-gray-50 file:text-gray-700 hover:file:bg-gray-100 hover:file:cursor-pointer'
                                    onChange={(event) => {
                                        const pics: any[] = [];
                                        if (event) {
                                            if (!event.target.files) return;
                                            Array.from(event.target.files).forEach((file) => {
                                                pics.push(URL.createObjectURL(file))
                                            })

                                            // console.log(pics)
                                            setPictures(prevPictures => [...prevPictures, ...pics])
                                        }
                                    }} /> */}


                                </div>
                            }

                            {
                                !valid &&

                                <p className="text-xs text-error-500 mt-3">All fields marked with * are required</p>
                            }

                            <div className='mt-4'>
                                <Button loading={loading} disabled={!valid} onClick={() => {
                                    setLoading(true)
                                    addService()
                                }} size={'sm'}>Add service</Button>
                            </div>

                        </>



                    </>}




            </div>

            {
                isViewerOpen && <div className={`transition-all opcacity-0 ${isViewerOpen && "opcacity-100"}`}>
                    <ImageViewer
                        src={[...pictures]}
                        backgroundStyle={{ zIndex: 99999, backgroundColor: "#fff" }}
                        currentIndex={currentImage}
                        disableScroll={true}
                        closeOnClickOutside={true}
                        onClose={closeImageViewer}
                        closeComponent={<Icon.X color='#000' />}
                        leftArrowComponent={<Icon.ArrowLeft color='#000' />}
                        rightArrowComponent={<Icon.ArrowRight color='#000' />}

                    />
                    {/* <p className='text-white'
                    style={{ 'zIndex': 999999 }}
                >Salut</p> */}
                </div>
            }
        </>
    )
}

export default AddService