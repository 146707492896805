import React, { useEffect, useRef, useState } from 'react'
import ContentPage from '../../components/common/ContentPage'
import Section from '../../components/common/Section'
import TextInput from '../../components/input/TextInput'
import Select from 'react-select'
import hours from "../../utils/day_hours.json"
import { fetchBusinessByID } from '../../api/booking'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { updateShopProfile } from '../../utils/ShopProfileTasks'
import toast, { Toaster } from 'react-hot-toast'
import { shopSelector } from '../../store/models/shopModel'
import { showToast, uploadFile, uploadImage } from '../../utils/utilities'
import ImageCropper from '../../components/ImageCropper'
import Compressor from 'compressorjs'
import { Oval } from 'react-loader-spinner'
import businesses from "../../utils/businesses.json"
import { canEditBusinessProfile } from '../../utils/roles'
import { userSelector } from '../../store/models/userModel'
import clsx from 'clsx'
import UpgradeButton from '../../components/UpgradeButton'


var hoursArray: any[] = []
var businessesArray: any[] = []

function ShopProfile() {

  const imageRef = useRef<any>()


  const [picURL, setPicURL] = useState<any>() //cropper uses this as input, either profile or banner

  const [croppedProfilePicURL, setCroppedProfilePicURL] = useState<any>() //cropper generates this
  const [compressedProfile, setCompressedProfile] = useState<any>() //this is what is compressed and uploaded

  const [croppedBannerPicURL, setCroppedBannerPicURL] = useState<any>() //cropper generates this
  const [compressedBanner, setCompressedBanner] = useState<any>() //this is what is compressed and uploaded

  const [selectingImage, setSelectingImage] = useState<"banner" | "profile" | null>(null)

  const daysOfTheWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
  const typesOfShops = ["Barbershop", "Hairdresser", "Spa", "Nail salons", "Make-up", "Wigs & Weave-on"]

  const { roles } = useSelector(userSelector);



  const [profile, setProfile] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [workingHours, setWorkingHours] = useState<any>({
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  })

  // useEffect(() => {
  //   console.log("hours", workingHours)
  // }, [workingHours])

  // refs to the different fields
  const shopNameRef = useRef<any>()
  const emailRef = useRef<any>()
  const shopNumberRef = useRef<any>()
  const cityRef = useRef<any>()
  const slotRef = useRef<any>()
  const addressRef = useRef<any>()
  const aboutRef = useRef<any>()

  useEffect(() => {
    hoursArray = []
    hours.map((hours) => {
      hoursArray.push({
        label: `${hours.standard_format} ${hours.time_of_day}`,
        value: hours.military_format
      })
    })

    // console.log(hoursArray)
  }, [])

  useEffect(() => {
    businessesArray = []
    businesses.map((business) => {
      businessesArray.push({
        label: business,
        value: business
      })
    })

    // console.log(hoursArray)
  }, [])

  // send the profile data to the backend
  const submit = async () => {
    setLoading(true)

    var dataToUpdate: any = {
      name: shopNameRef.current?.getInputText(),
      email: emailRef.current?.getInputText(),
      phone: shopNumberRef.current?.getInputText(),
      city: cityRef.current?.getInputText(),
      address: addressRef.current?.getInputText(),
      about: aboutRef.current?.getInputText(),
      categories,
      // working_end: endTime?.value,
      // working_start: startTime?.value,
      // slot_duration: slotRef.current?.getInputText(),
      isLive: isLive?.value,
      workingHours

    }

    await uploadFile(compressedProfile, `businesses/${selectedShop}/profile/profile_picture`)
      .then(async (uploadedPicURL) => {
        // console.log("Profile picture URL", uploadedPicURL)

        if (uploadedPicURL) {
          dataToUpdate = {
            ...dataToUpdate,
            logo: uploadedPicURL,
          }
        } else {
          dataToUpdate = {
            ...dataToUpdate,
            logo: croppedProfilePicURL,
          }
        }


        await uploadFile(compressedBanner, `businesses/${selectedShop}/profile/banner`)
          .then(async (uploadedBannerURL) => {



            if (uploadedBannerURL) {
              dataToUpdate = {
                ...dataToUpdate,
                banner: uploadedBannerURL,
              }
            } else {
              dataToUpdate = {
                ...dataToUpdate,
                banner: croppedBannerPicURL,
              }
            }
            // console.log("dataToUpdate", dataToUpdate)
            await updateShopProfile({ queryKey: ["shopID", selectedShop] }, dataToUpdate)
              .then(() => {
                setLoading(false)
                showToast("Your data has been saved", "success")
              }).catch((e) => {
                setLoading(false)
                showToast("We couldn't save your data, check your internet and try again", "error", 7000)
              })
          }).catch((e) => {
            setLoading(false)
            showToast("We couldn't save your data, check your internet and try again", "error", 7000)
          })
      }).catch((e) => {
        setLoading(false)
        showToast("We couldn't save your data, check your internet and try again", "error", 7000)
      })
  }


  const { shopsData, selectedShop } = useSelector(shopSelector);

  const [subscription, setSubscription] = useState<any>({})
  useEffect(() => {
    setSubscription(shopsData[selectedShop].settings.subscription);
  }, [shopsData, selectedShop])

  const [categories, setCategories] = useState<string[]>([])
  const [startTime, setStartTime] = useState<any>()
  const [endTime, setEndTime] = useState<any>()
  const [isLive, setIsLive] = useState<any>()
  const [businessName, setBusinessName] = useState("");

  // load existing details from backend
  useEffect(() => {
    // console.log("Loading current details...")
    async function fetchCurrentShopData() {
      await fetchBusinessByID({ queryKey: ["shopID", selectedShop] })
        .then((data) => {
          // console.log(data)
          setBusinessName(data?.name);
          shopNameRef.current?.setValue(data?.name ? data?.name : "")
          emailRef.current?.setValue(data?.email ? data?.email : "")
          shopNumberRef.current?.setValue(data?.phone ? data?.phone : "")
          cityRef?.current?.setValue(data?.city ? data?.city : "")
          slotRef?.current?.setValue(data?.slot_duration)
          addressRef?.current?.setValue(data?.address)
          aboutRef?.current?.setValue(data?.about)
          setCroppedProfilePicURL(data?.logo)
          setWorkingHours(data?.workingHours)
          setCroppedBannerPicURL(data?.banner)
          setCategories(data?.categories)
          // setStartTime(fetchTime(data?.working_start))
          setStartTime({
            label: moment(data?.working_start, "HHmm").format("LT").toLowerCase(),
            value: data?.working_start
          })
          setEndTime({
            label: moment(data?.working_end, "HHmm").format("LT").toLowerCase(),
            value: data?.working_end
          })
          setIsLive({
            label: data?.isLive ? "Active" : "Inactive",
            value: data?.isLive ? true : false
          })
        })
    }
    fetchCurrentShopData()
  }, [selectedShop])


  // compress selected profile image
  useEffect(() => {
    const generateImageURL = async () => {
      let blob = await fetch(croppedProfilePicURL).then(r => r.blob());
      new Compressor(blob, {
        quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
        convertTypes: ['image/png', 'image/jpeg'],
        convertSize: 1000000,
        success: async (compressedResult) => {
          setCompressedProfile(compressedResult)
        },
      });
    }
    if (croppedProfilePicURL) {
      generateImageURL()
    }
  }, [croppedProfilePicURL])

  // compress selected Banner image
  useEffect(() => {
    const generateImageURL = async () => {
      try {
        let blob = await fetch(croppedBannerPicURL).then(r => r.blob());
        new Compressor(blob, {
          quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
          convertTypes: ['image/png', 'image/jpeg'],
          convertSize: 1000000,
          success: async (compressedResult) => {
            setCompressedBanner(compressedResult)
          },
        });
      } catch (e) {

      }
    }
    if (croppedBannerPicURL) {
      generateImageURL()
    }
  }, [croppedBannerPicURL])





  return (
    <>
      {selectingImage &&
        <div className=''>
          <div className="">
            <ImageCropper ref={imageRef} image={picURL} uploadButtonText="Choose image" aspectRatio={selectingImage === "profile" ? 1 : 3.84}
              onUpload={() => {
                if (selectingImage === "profile") {
                  setCroppedProfilePicURL(imageRef.current.getImageURL())
                } else if (selectingImage === "banner") {
                  setCroppedBannerPicURL(imageRef.current.getImageURL())
                }

                setSelectingImage(null)
              }}

              onCancel={() => {
                setSelectingImage(null)
              }}

            />

          </div>

        </div>
      }
      <ContentPage loadingAction={loading} title={`Your business' profile ${businessName ? `(${businessName})` : ""}`} subtitle='Update your profile and what clients see' actionText='Save' showActionButton={canEditBusinessProfile(roles)} onClickAction={submit}>
        <div className='sm:px-6 sm:pt-6 p-4'>
          <div className=''>

            {canEditBusinessProfile(roles) &&
              <Section title='Active on Wavier' subtitle='Make your business active or incative on Wavier and let client book appointements'>
                <div className={clsx(
                  "max-w-xs w-full",
                  subscription?.billingState === "inactive" && "pointer-events-none opacity-50"
                )}>
                  <Select options={[{
                    label: "Active",
                    value: true
                  },
                  {
                    label: "Inactive",
                    value: false
                  }]} onChange={(selectedValue: any) => {

                    setIsLive(selectedValue)
                  }} value={isLive} />
                </div>

                {subscription?.billingState === "inactive" &&
                  <div className='max-w-md mt-4'>
                    <p className=" gap-1 inline">
                      {`You're not subscribed to a Wavier plan. Please upgrade to make your business active`}
                    </p>
                    <UpgradeButton color='purple' />
                  </div>
                }


              </Section>
            }

          </div>



          <Section title='Public profile' subtitle='Set up your profile'>
            <div className='grid sm:grid-cols-2 gap-4 w-max'>
              <TextInput
                ref={shopNameRef}
                placeholder='Enter your Business name'
                showLabel
                label="Business name"
                disabled={!canEditBusinessProfile(roles)}
              />
              <TextInput
                ref={emailRef}
                placeholder='example@something.com'
                showLabel
                label="Email address"
                type={"email"}
                disabled={!canEditBusinessProfile(roles)}
              />
              <TextInput
                ref={shopNumberRef}
                placeholder='ie: +233550000000, +15555551234'
                showLabel
                disabled={!canEditBusinessProfile(roles)}
                label="Phone (including country code)"
              />
              <TextInput
                ref={cityRef}
                placeholder='e.g: Accra, Kumasi, etc.'
                showLabel
                label="City"
                disabled={!canEditBusinessProfile(roles)}
              />
              <TextInput
                ref={addressRef}
                placeholder='e.g: East Legon, 3rd Avenue'
                showLabel
                label="Address"
                disabled={!canEditBusinessProfile(roles)}
              />
            </div>

            <div className='mt-4'>
              <TextInput
                area
                ref={aboutRef}
                placeholder='e.g: East Legon, 3rd Avenue'
                showLabel
                label="About (describe your business)"
                disabled={!canEditBusinessProfile(roles)}
              />
            </div>
          </Section>



          <Section title='Profile picture & Banner' subtitle='Set up your profile picture and banner. We recommend using your logo and branded content'>
            <div className='flex gap-4 flex-col'>

              <div className='flex flex-col w-64 gap-4'>
                <label className='text-md text-gray-600 mb-2'>Profile picture</label>

                <div className='h-64 w-64'>
                  {croppedProfilePicURL === undefined || croppedProfilePicURL === null ?
                    <>
                      <div className='flex items-center h-full w-full justify-center'>
                        <Oval color='#ffffff' secondaryColor='#7D7D7D' width={24} />
                      </div>
                    </> :
                    <>
                      {croppedProfilePicURL.length === 0 ?
                        <div className='w-full h-full flex justify-center items-center text-sm text-gray-500'>No logo chosen</div>
                        :
                        <img className="h-64 w-64 rounded-lg object-cover object-center" src={croppedProfilePicURL} alt="profile pic"></img>
                      }
                    </>}
                </div>
                {canEditBusinessProfile(roles) &&
                  <input id='profile_pic' type="file" className='block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-gray-50 file:text-gray-700 hover:file:bg-gray-100 hover:file:cursor-pointer'
                    onChange={(event) => {
                      if (event) {
                        if (!event.target.files) return;
                        // setProfilePic(event.target.files[0]);
                        // setProfilePicURL(URL.createObjectURL(event.target.files[0]));
                        setPicURL(URL.createObjectURL(event.target.files[0]));
                        setSelectingImage("profile")

                      }
                    }} />
                }
              </div>
              <div className='w-full flex flex-col gap-4 max-w-[758px]'>
                <label className='text-md text-gray-600 mb-2'>Banner</label>
                <div className='w-full h-[200px] rounded-lg bg-clip-border border border-gray-100'>
                  {croppedBannerPicURL === undefined || croppedBannerPicURL === null ?
                    <>
                      <div className='flex items-center h-full w-full justify-center'>
                        <Oval color='#ffffff' secondaryColor='#7D7D7D' width={24} />
                      </div>
                    </> :
                    <>
                      {croppedBannerPicURL.length === 0 ?
                        <div className='w-full h-full flex justify-center items-center text-sm text-gray-500'>No banner chosen</div>
                        :
                        <img className="h-[200px] w-full rounded-lg object-contain object-center" src={croppedBannerPicURL} alt="banner pic"></img>
                      }

                    </>}

                </div>
                {canEditBusinessProfile(roles) &&
                  <input id='profile_pic' type="file" className='block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-gray-50 file:text-gray-700 hover:file:bg-gray-100 hover:file:cursor-pointer'
                    onChange={(event) => {
                      if (event) {
                        if (!event.target.files) return;
                        // setProfilePic(event.target.files[0]);
                        setPicURL(URL.createObjectURL(event.target.files[0]));
                        setSelectingImage("banner")

                      }
                    }} />
                }
              </div>
            </div>
          </Section>

          <Section title='Business info' subtitle='Give more info about your business'>
            <div>
              <div className='mb-8'>
                <div>
                  <div className='text-md font-semibold text-grary-900'>
                    Category of business
                  </div>
                  <div className='text-md text-gray-600'>
                    Let your customers know what category of services you offer
                  </div>
                </div>
                <div className='grid grid-cols-2 mt-4 w-full'>
                  {/* {typesOfShops.map((type) => {
                    return (
                      <div className='flex gap-2'>
                        <input type={"checkbox"} checked={categories?.includes(type)} onChange={(value) => {
                          if (value.target.checked) {
                            categories.push(type)
                          } else {
                            const existingItem = categories.indexOf(type);
                            categories.splice(existingItem, 1);
                          }
                          setCategories(categories)
                          setProfile({
                            ...profile,
                            categories
                          })
                        }} />
                        <div>
                          {type}
                        </div>
                      </div>
                    )
                  })} */}

                  {canEditBusinessProfile(roles) ?
                    <>
                      <Select options={businessesArray} value={{
                        label: categories[0],
                        value: categories[0]
                      }} onChange={(selectedValue: any) => {
                        // if (!categories.includes(selectedValue?.value)) {
                        //   categories.push(selectedValue?.value)
                        // } else {
                        //   const existingItem = categories.indexOf(selectedValue?.value);
                        //   categories.splice(existingItem, 1);
                        // }
                        setCategories([selectedValue?.value])
                        setProfile({
                          ...profile,
                          categories
                        })
                        // console.log(selectedValue)
                      }} />
                    </>
                    :
                    <>
                      {categories}
                    </>}

                </div>
              </div>

              <div className='text-md font-semibold text-grary-900'>
                Working hours
              </div>
              {daysOfTheWeek.map((day) => {
                // console.log("working day", workingHours[day.toLowerCase()])

                const dayWorkingHours = workingHours[day.toLowerCase()];
                return (
                  <div className='flex mb-2 flex-col md:flex-row pb-4 md:pb-0'>
                    <div className='flex gap-2 items-center w-32 pb-2 md:pb-0'>
                      <input checked={workingHours[day.toLowerCase()] !== false ? true : false} disabled={!canEditBusinessProfile(roles)} onChange={(change) => {
                        const weekDay = day.toLowerCase()
                        var woringHoursObj;

                        if (!change.target.checked) {
                          woringHoursObj = {
                            ...workingHours,
                            [weekDay]: false
                          }
                        } else {
                          woringHoursObj = {
                            ...workingHours,
                            [weekDay]: {
                              start: "",
                              end: ""
                            }
                          }
                        }

                        // console.log(woringHoursObj)

                        setWorkingHours(woringHoursObj)

                        // console.log("checking", hoursArray.filter(hour => hour.value === workingHours[day.toLowerCase()]))
                        // console.log("checking", workingHours[day.toLowerCase()])
                        // console.log("checking 2", hoursArray && workingHours[day.toLowerCase()])
                      }} type={"checkbox"} />
                      <div>
                        {day}
                      </div>
                    </div>
                    {workingHours[day.toLowerCase()] !== false && (
                      <div className='flex items-center gap-4 '>
                        {canEditBusinessProfile(roles) &&
                          <Select options={hoursArray} onChange={(selectedValue) => {
                            // console.log(selectedValue)

                            const weekDay = day.toLowerCase()

                            const woringHoursObj = {
                              ...workingHours,
                              [weekDay]: {
                                end: workingHours[weekDay].end,
                                start: selectedValue?.value,

                              }
                            }

                            setWorkingHours(woringHoursObj)
                          }}
                            defaultValue={dayWorkingHours && dayWorkingHours.start ?
                              {
                                label: hoursArray.filter(hour => hour.value === dayWorkingHours.start)[0]?.label,
                                value: dayWorkingHours.start
                              }
                              :
                              { value: '', label: 'Select...' }
                            }
                          />
                        }
                        {!canEditBusinessProfile(roles) &&
                          <div>{hoursArray.filter(hour => hour.value === dayWorkingHours.start)[0]?.label}</div>
                        }
                        <div>
                          to
                        </div>
                        {canEditBusinessProfile(roles) &&
                          <Select options={hoursArray} onChange={(selectedValue) => {
                            // console.log(selectedValue)

                            const weekDay = day.toLowerCase()
                            const woringHoursObj = {
                              ...workingHours,
                              [weekDay]: {
                                start: workingHours[weekDay].start,
                                end: selectedValue?.value,

                              }
                            }

                            setWorkingHours(woringHoursObj)
                          }}

                            defaultValue={dayWorkingHours && dayWorkingHours.end ?
                              {
                                label: hoursArray.filter(hour => hour.value === dayWorkingHours.end)[0]?.label,
                                value: dayWorkingHours.end
                              }
                              :
                              { value: '', label: 'Select...' }
                            }
                          />
                        }

                        {!canEditBusinessProfile(roles) &&
                          <div>{hoursArray.filter(hour => hour.value === dayWorkingHours.end)[0]?.label}</div>
                        }
                      </div>
                    )}

                  </div>
                )
              })}
              {/* <div className='flex items-center gap-4 mt-4'>
                <div className='w-40'>
                  <Select options={hoursArray} onChange={(selectedValue: any) => {
                    console.log(selectedValue)
                    const time: any = selectedValue?.value
                    // setStartTime(time as string)
                    setStartTime(selectedValue)
                  }}
                    value={startTime} />
                </div>
                <div>
                  to
                </div>
                <div className='w-40'>
                  <Select options={hoursArray} onChange={(selectedValue: any) => {
                    console.log(selectedValue)
                    const time: any = selectedValue?.value
                    // setStartTime(time as string)
                    setEndTime(selectedValue)
                  }} value={endTime} />
                </div>
              </div> */}

              {/* <div className='text-md font-semibold text-grary-900 mt-4'>
                Slot duration
              </div>
              <div className='text-md text-gray-600'>
                Set the slot time duration for each apppointment
              </div>

              <div className='flex items-center gap-4 mt-4'>
                <TextInput ref={slotRef} placeholder='Slot duration in minutes' />
                <div>
                  minutes
                </div>
              </div> */}
            </div>
          </Section>
        </div>
        <Toaster />
      </ContentPage>
    </>
  )
}

export default ShopProfile