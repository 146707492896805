import React, { useEffect, useRef, useState } from 'react'
import TextInput from '../input/TextInput'
import { useSelector } from 'react-redux'
import { userSelector } from '../../store/models/userModel'
import { shopSelector } from '../../store/models/shopModel'
import { updateShopProfile } from '../../utils/ShopProfileTasks'
import { showToast } from '../../utils/utilities'
import OnboardingNavigation from './OnboardingNavigation'
import Select from 'react-select'
import currencies from "../../utils/currencies.json"

interface IBusinessProfileOnboarding {
    onNext?: () => void,
    onPrevious?: () => void,
    onSkip?: () => void
}

function BusinessProfileOnboarding({
    onNext,
    onPrevious,
    onSkip,
}: IBusinessProfileOnboarding) {

    // refs to the different fields
    const shopNameRef = useRef<any>()
    const emailRef = useRef<any>()
    const shopNumberRef = useRef<any>()
    const cityRef = useRef<any>()
    const addressRef = useRef<any>()

    const { roles, businesses } = useSelector(userSelector)
    const { selectedShop, shopsData } = useSelector(shopSelector)

    const [city, setCity] = useState("")

    const [currencyList, setCurrencyList] = useState<any>([])
    const [selectedCurrency, setSelectedCurrency] = useState<any>({
        label: "Ghanaian Cedi (GH₵)",
        value: "GH₵"
    })
    useEffect(() => {
        const currencyArray = Object.keys(currencies).map((currency: string) => {
            // @ts-ignore
            const symbol = currencies[currency as any].symbol
            // @ts-ignore
            const name = currencies[currency as any].name
            return {
                label: `${name} (${symbol})`,
                value: symbol
            }
        })
        setCurrencyList(currencyArray)
    }, [])


    useEffect(() => {
        shopNameRef.current?.setValue(shopsData[selectedShop].name);
        emailRef.current?.setValue(shopsData[selectedShop].email)
        shopNumberRef.current?.setValue(shopsData[selectedShop].phone)
        cityRef?.current?.setValue(shopsData[selectedShop].city)
        setCity(shopsData[selectedShop].city);
        addressRef?.current?.setValue("")

    }, [selectedShop])

    const [loading, setLoading] = useState(false)

    const updateProfile = async () => {
        setLoading(true);
        var dataToUpdate: any = {
            name: shopNameRef.current?.getInputText(),
            email: emailRef.current?.getInputText(),
            phone: shopNumberRef.current?.getInputText(),
            city: cityRef.current?.getInputText(),
            address: addressRef.current?.getInputText(),
            currency: selectedCurrency.value,
        }


        await updateShopProfile({ queryKey: ["shopID", selectedShop] }, dataToUpdate)
            .then(() => {
                setLoading(false)
                showToast("Your data has been saved", "success")



                onNext && onNext()
            }).catch((e) => {
                setLoading(false)
                showToast("We couldn't save your data, check your internet and try again", "error", 7000)
            })
    }

    return (
        <>
            <div className='grid sm:grid-cols-2 gap-4 w-max '>
                <TextInput
                    ref={shopNameRef}
                    placeholder='Enter your Business name'
                    showLabel
                    label="Business name"
                />
                <TextInput
                    ref={emailRef}
                    placeholder='example@something.com'
                    showLabel
                    label="Email address"
                    type={"email"}
                />
                <TextInput
                    ref={shopNumberRef}
                    placeholder='ie: +233550000000, +15555551234'
                    showLabel
                    label="Phone (including country code)"
                />
                <div className="max-w-sm space-y-2">
                    <div className='text-sm font-medium'>
                        Currency
                    </div>
                    <Select options={currencyList} onChange={(change) => {
                        setSelectedCurrency(change)
                        console.log(change)
                    }}
                        value={selectedCurrency}
                    />

                </div>
                <TextInput
                    ref={cityRef}
                    placeholder='e.g: Accra, Kumasi, etc.'
                    showLabel
                    label="City"
                    onChange={() => {
                        setCity(cityRef.current?.getInputText())
                    }}
                    hint={city.length > 0 ? undefined : "Please specify your city"}
                    error
                />
                <TextInput
                    ref={addressRef}
                    placeholder='e.g: East Legon, 3rd Avenue'
                    showLabel
                    label="Address"
                />
            </div>
            <OnboardingNavigation
                canNext={city.length > 0}
                canPrevious={false}
                showNext={true}
                showPrevious={false}
                showSkip={false}
                canSkip={false}
                onNext={updateProfile}
                onPrevious={onPrevious}
                onSkip={onSkip}
                loading={loading} />
        </>
    )
}

export default BusinessProfileOnboarding