import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import * as Icon from "react-feather"
import { useDispatch, useSelector } from 'react-redux'
import { modalSelector } from '../../store/models/modals'
import { userSelector } from '../../store/models/userModel'
import { analyticsEvents } from '../../utils/Events'
import { Button } from '../button'
import { usePostHog } from 'posthog-js/react'
import { shopSelector } from '../../store/models/shopModel'
import { editCategory, fetchCategories, saveCategory } from '../../api/api'
import TextInput from '../input/TextInput'

function EditCategoryModal() {

    const { selectedShop, selectedCategory } = useSelector(shopSelector)
    const posthog = usePostHog()

    const { editCategoryModal } = useSelector(modalSelector)

    const [loading, setLoading] = useState(false)
    const [categories, setCategories] = useState<any[]>([]);

    const dispatch = useDispatch();

    const nameRef = useRef<any>()
    const descriptionRef = useRef<any>()

    const [name, setName] = useState<string>("")
    const [description, setDescription] = useState<string>("")

    const toggleModal = () => {
        dispatch.Modals.toggleEditCategoryModal()
    }

    const fetchData = async () => {
        const fetchedCategories = await fetchCategories(selectedShop);
        setCategories(fetchedCategories);

        console.log(fetchedCategories);
    }

    useEffect(() => {
        if (selectedShop && editCategoryModal) {
            fetchData()
        }
    }, [selectedShop, editCategoryModal]);

    useEffect(() => {
        setName(selectedCategory?.name);
        setDescription(selectedCategory?.description);

        nameRef.current?.setValue(selectedCategory?.name);
        descriptionRef.current?.setValue(selectedCategory?.description);
    }, [selectedCategory])

    return (
        <>
            {editCategoryModal &&
                <>
                    <div className="absolute w-full h-screen opacity-50 z-40 bg-gray-900  flex justify-center items-center">

                    </div>
                    <div className="absolute w-full h-screen z-50 backdrop-blur-sm flex justify-center items-center">

                        <div className='gap-2 p-6 w-full max-w-lg flex flex-col justify-start bg-white rounded-lg mx-4'>
                            <div className='w-full flex justify-end'>
                                <div className="cursor-pointer"
                                    onClick={toggleModal}
                                >
                                    <Icon.X color='#98a2b3' size={16} />
                                </div>
                            </div>
                            <div className='font-semibold text-xl'>
                                Edit category
                            </div>

                            <div className='w-full'>

                                <div className='mt-4'>
                                    <TextInput error hint={name?.length === 0 ? "Please specify a category name" : undefined} onChange={() => {

                                        setName(nameRef.current.getInputText())
                                    }} ref={nameRef} label='Category name' placeholder="ie: Haircuts, Braids"></TextInput>
                                </div>
                                <div className='mt-4'>
                                    <TextInput area onChange={() => {

                                        setDescription(descriptionRef.current.getInputText())
                                    }} ref={descriptionRef} label='Description' placeholder='Write your description here...'></TextInput>

                                </div>

                            </div>
                            <div className='flex flex-col gap-2 mt-2'>

                                <Button disabled={name?.length === 0} loading={loading} onClick={async () => {
                                    try {
                                        setLoading(true)
                                        const newAppointmentID = await editCategory(selectedShop,selectedCategory.id, name, description);

                                        setLoading(false)
                                        toggleModal();
                                        dispatch.Modals.toggleCategoriesModal();
                                    } catch (e) {
                                        console.log("Couldn't save category. Error: ", e)
                                    }
                                }} size={'sm'}>
                                    Save
                                </Button>

                                <Button secondaryGray disabled={false} onClick={() => {
                                    toggleModal();
                                    dispatch.Modals.toggleCategoriesModal();
                                    setLoading(false)
                                }} size={'sm'}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            }

        </>
    )
}

export default EditCategoryModal