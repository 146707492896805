import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useReactTable, createColumnHelper, getCoreRowModel, flexRender, getSortedRowModel, SortingState } from '@tanstack/react-table'
import moment from 'moment'
import TableHeader from './table/TableHeader'
import { useIsMobile } from '../hooks/useIsMobile'
import { useSelector } from 'react-redux'
import { shopSelector } from '../store/models/shopModel'

type Service = {
    name: string
    price: string
    duration: string,
    category: string
}

type TServiceTable = {
    data: any,
    currentBooking?: () => number,
    onRowClick: () => void
}

const columHelper = createColumnHelper<Service>()


const tableColumns = [
    columHelper.accessor("category", {
        cell: info => <div className='font-medium text-sm text-gray-900'>{info.getValue()}</div>,
        header: (header) =>
            <div onClick={header.column.getToggleSortingHandler()}>
                <TableHeader showSorting sortingOder={header.column.getIsSorted()}>Category</TableHeader>
            </div>,
        footer: info => info.column.id,
    }),
    columHelper.accessor("name", {
        cell: info => <div className='font-medium text-sm text-gray-900'>{info.getValue()}</div>,
        header: (header) => <div onClick={header.column.getToggleSortingHandler()}>
            <TableHeader showSorting sortingOder={header.column.getIsSorted()}>Services</TableHeader>
        </div>,
        footer: info => info.column.id,
    }),
    columHelper.accessor("price", {
        cell: info => <div className='text-xs text-gray-500'>{`${info.getValue()}`}</div>,
        header: (header) => <div onClick={header.column.getToggleSortingHandler()}>
            <TableHeader showSorting sortingOder={header.column.getIsSorted()}>Prices</TableHeader>
        </div>,
        footer: info => info.column.id,

    }),
    columHelper.accessor("duration", {
        cell: info => <div className='text-sm text-gray-900'>{`${Math.floor(parseInt(info.getValue()) / 60) === 0 ? "" : `${Math.floor(parseInt(info.getValue()) / 60)}h`}${parseInt(info.getValue()) % 60 === 0 ? "" : `${parseInt(info.getValue()) % 60}mins`}`}</div>,
        header: () => <TableHeader>Duration</TableHeader>,
        footer: info => info.column.id,
    }),

]

const tableColumnsMobile = [
    columHelper.accessor("category", {
        cell: info => <div className='font-medium text-sm text-gray-900'>{info.getValue()}</div>,
        header: (header) =>
            <div onClick={header.column.getToggleSortingHandler()}>
                <TableHeader showSorting sortingOder={header.column.getIsSorted()}>Category</TableHeader>
            </div>,
        footer: info => info.column.id,
    }),
    columHelper.accessor("name", {
        cell: info => <div className='font-medium text-sm text-gray-900'>{info.getValue()}</div>,
        header: (header) => <div onClick={header.column.getToggleSortingHandler()}>
            <TableHeader showSorting sortingOder={header.column.getIsSorted()}>Services</TableHeader>
        </div>,
        footer: info => info.column.id,
    }),
    columHelper.accessor("price", {
        cell: info => <div className='text-xs text-gray-500'>{`${info.getValue()}`}</div>,
        header: (header) => <div onClick={header.column.getToggleSortingHandler()}>
            <TableHeader showSorting sortingOder={header.column.getIsSorted()}>Prices</TableHeader>
        </div>,
        footer: info => info.column.id,

    }),

]

function ServiceTable({ data, onRowClick }: TServiceTable, ref: any) {

    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);

    const {isMobile} = useIsMobile()

    const [sorting, setSorting] = useState<SortingState>([]);

    const actualData: Service[] | any = [];
    const [tableData, setTableData] = useState<Service[]>(actualData)
    // filter out the data to keep only what is necessary  

    const { selectedShop, shopsData } = useSelector(shopSelector)

    if (data) {
        data?.map((dataItem: any, index: number) => {
            actualData.push({
                name: dataItem?.name,
                price: `${shopsData[selectedShop]?.currency ? shopsData[selectedShop]?.currency : ""} ${dataItem?.price}`,
                duration: dataItem?.duration_minutes,
                category: dataItem?.category,
            })
        })


    }

    // create the table using the library's hook
    const table = useReactTable({

        data: tableData,
        columns: isMobile ? tableColumnsMobile : tableColumns,
        getCoreRowModel: getCoreRowModel(),
        enableRowSelection: true,
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
        },
        onSortingChange: setSorting,
    })

    // set the first row of the table as selected by default
    useEffect(() => {
        if (actualData) {
            setTableData(actualData)
            table.getRowModel().rows.map((row) => {
                if (row.index === 0) {
                    table.toggleAllRowsSelected(false)
                    row.toggleSelected(true)
                    setSelectedRowIndex(row.index)
                }
            })
        }
    }, [data]) //don't listen to the debugger. Adding the full deps array create an infinite loop
    // pass up the current index of the data to show

    // make the selected index available outside
    useImperativeHandle(ref, () => {
        return {
            getSelectedServiceIndex: () => selectedRowIndex
        }
    }, [selectedRowIndex]);

    return (
        <div className='flex w-full '>
            <table className='w-full border-collapse overflow-auto'>
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id} >
                            {headerGroup.headers.map(header => (
                                <th key={header.id} className='p-0'>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                    {/* {header.column.getIsSorted() === "asc" ? "⬆" : "⬇"} */}

                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>

                <tbody onClick={onRowClick}>
                    {table.getRowModel().rows.map(row => (
                        <tr key={row.id}  onClick={() => {
                            table.toggleAllRowsSelected(false)
                            row.toggleSelected()
                            setSelectedRowIndex(row.index)

                        }} className={`${row.getIsSelected() && ("bg-gray-200 hover:bg-gray-200")} p-0 hover:bg-gray-100 cursor-pointer`}>
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id} className='p-0'>
                                    <div className='h-16 flex items-center px-4 border-2 border-[#EAECF0]/[.0] border-b-gray-100'>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </div>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default forwardRef(ServiceTable)