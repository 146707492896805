import { useEffect, useState } from 'react'

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState<boolean>()

    const setMedia = () => {
        // let { matches } = window.matchMedia('(max-width: 768px)')
        let { matches } = window.matchMedia('(max-width: 1024px)')
        setIsMobile(matches)
    }

    useEffect(() => {
        setMedia()
        window.addEventListener('resize', setMedia)

        return () => window.removeEventListener('resize', setMedia)
    }, [])

    return {
        isMobile,
    }
}
