import React, { useContext, useEffect, useState } from 'react'
import { Oval } from 'react-loader-spinner'
import { fetchBusinessByID } from '../api/booking'
import * as Icon from "react-feather"
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { shopSelector } from '../store/models/shopModel'
import { userSelector } from '../store/models/userModel'
import { analyticsEvents } from '../utils/Events'
import { usePostHog } from 'posthog-js/react'

interface IShopDropdown {
    shopIDs: string[],

}

function ShopDropdown({
    shopIDs,
}: IShopDropdown) {
    const posthog = usePostHog()
    const [shopsNames, setShopsNames] = useState<string[]>([])

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedShopName, setSelectedShopName] = useState<string>("")

    // const { shopsData, selectedShop } = useSelector(shopSelector)

    const { subscription } = useSelector(userSelector);

    const dispatch = useDispatch();
    const navigate = useNavigate()

    // use the shop IDs to fetch the names of all the shops
    useEffect(() => {
        const getShopName = async () => {

            try {
                var shopNamesArray: string[] = []
                shopIDs?.map(async (shopID: any, index: number) => {
                    await fetchBusinessByID({ queryKey: [shopID, shopID] })
                        .then((data) => {
                            shopNamesArray.push(data?.name)
                            setShopsNames(shopNamesArray);
                        })

                })
            } catch (e) {
                console.log("Error why fatching shop on Sidebar dropdown. Error", e)
            }


        }
        getShopName()
    }, [shopIDs])

    // once the fetch is done, select the first shop as the default display shop
    useEffect(() => {
        if (shopsNames) {
            setSelectedShopName(shopsNames[0])
        }
    }, [shopsNames]);

    return (
        <div>
            <div
                className='mx-6 rounded-lg bg-gray-800'
            >
                {shopsNames.length !== 0 ?
                    <>
                        <div className={`flex items-center justify-between px-4 py-3 cursor-pointer   `} onClick={() => {
                            setIsOpen(!isOpen)
                        }}>
                            <div className="flex items-center text-md font-bold text-white">
                                {shopsNames ?
                                    <>
                                        {selectedShopName}
                                    </>
                                    :
                                    <>
                                        <div className='flex w-full h-6 justify-center items-center'>
                                            <Oval color='#ffffff' secondaryColor='#7D7D7D' width={16} />
                                        </div>
                                    </>}
                            </div>
                            <Icon.ChevronDown color='#ffffff' />
                        </div>
                        {isOpen && (
                            <div>
                                <hr className='border-gray-700 mx-8'></hr>
                                {shopsNames.map((shop, index: number) => {
                                    // console.log(`@ index ${index} we have shop ${shop}`)
                                    return (
                                        <div key={index} className={`flex items-center justify-between px-4 py-3 cursor-pointer hover:bg-gray-600  `} onClick={() => {
                                            setSelectedShopName(shopsNames[index])
                                            setIsOpen(false)

                                            console.log(shopIDs[index])

                                            dispatch.Shop.setSelectedShop(shopIDs[index])
                                            dispatch.AppointmentModel.setLoadingAppointments(true)
         
                                            dispatch.Professionals.selectedProIndex(0)
                                        }}>
                                            <div className="flex items-center text-md font-bold text-white">
                                                {shopsNames ?
                                                    <>
                                                        {shop}
                                                    </>
                                                    :
                                                    <>
                                                        <div className='flex w-full h-6 justify-center items-center'>
                                                            <Oval color='#ffffff' secondaryColor='#7D7D7D' width={16} />
                                                        </div>
                                                    </>}
                                            </div>
                                        </div>
                                    )
                                })}
                               

                            </div>
                        )}
                    </>
                    :
                    <>
                        <div className='flex w-full py-4 justify-center items-center bg-red100'>
                            <Oval color='#ffffff' secondaryColor='#7D7D7D' height={16} width={16} />
                        </div>
                    </>}

            </div>
        </div>
    )
}

export default ShopDropdown