import ReactGA from "react-ga4"
import { usePostHog } from 'posthog-js/react'

// appointments
const checkout = () => ReactGA.event("checkout")
const noShow = () => ReactGA.event("no_show")
const acceptAppointment = () => ReactGA.event("accept_appointment")
const rejectAppointment = () => ReactGA.event("reject_appointment")
const appointmentViewHistory = () => ReactGA.event("appointment_view_history")
const appointmentViewUpcoming = () => ReactGA.event("appointment_view_upcoming")

// subscribe
const attemptUpgrade = (position: "add_business" | "add_pro" | "upgrade_header") => ReactGA.event("attempt_upgrade", {
    attempt_upgrade_position: position
})

const clickUpgrade = (position: "plan_page" | "upgrade_modal") => ReactGA.event("click_upgrade", {
    click_upgrade_position: position
})

//auth
const login = () => ReactGA.event("login")
const logout = () => ReactGA.event("logout")

// sign up
const onboardingRequest = () => ReactGA.event("onboarding_request")

export const analyticsEvents = {
    checkout,
    noShow,
    acceptAppointment,
    rejectAppointment,
    appointmentViewHistory,
    appointmentViewUpcoming,
    login,
    logout,
    onboardingRequest,
    attemptUpgrade,
    clickUpgrade
}