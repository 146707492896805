import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ContentPage from '../../components/common/ContentPage'
import Section from '../../components/common/Section'
import PlanCard from '../../components/PlanCard'
import { userSelector } from '../../store/models/userModel'
import { analyticsEvents } from '../../utils/Events'
import { usePostHog } from 'posthog-js/react'
import { shopSelector } from '../../store/models/shopModel'
import moment from 'moment'
import { differenceInCalendarDays } from 'date-fns'
import clsx from 'clsx'
import { professionalSelector } from '../../store/models/professionalsModel'
import { MAX_USERS_PRO, MAX_USERS_STARTER } from '../../constants/plans'

function PlansPage() {

  return (
    <ContentPage title="Manage your subscription & billing info" actionText=''>
      <div className='p-8'>
        <Section title='Subscription' subtitle='Manage your subscription & upgrade to higher plans. To manage your billing details, click on Manage subscription'>
          <>
            <Plans />
          </>
        </Section>
      </div>
    </ContentPage>
  )
}

export default PlansPage

export const Plans = () => {

  const { email } = useSelector(userSelector);
  const { shopsData, selectedShop } = useSelector(shopSelector);
  const { usersData } = useSelector(professionalSelector);

  const [subscription, setSubscription] = useState<any>({})
  useEffect(() => {
    setSubscription(shopsData[selectedShop].settings.subscription);
  }, [shopsData, selectedShop])





  const [manageLink, setManageLink] = useState("")

  const [billingInterval, setBillingInterval] = useState<"monthly" | "yearly">("yearly");

  const posthog = usePostHog()

  useEffect(() => {
    const fetchManageLink = async () => {
  
      const response = await axios.get(`https://api.paystack.co/subscription/${subscription?.subscriptionCode}/manage/link`, {
        headers: {
          // "Authorization": process.env.NODE_ENV === "development" ? process.env.REACT_APP_PAYSTACK_TEST : process.env.REACT_APP_PAYSTACK_LIVE,
          "Authorization": "Bearer sk_test_db859d0be38abc5263e8b96483fbe007e6110571",
          "Content-Type": "application/json",
        },
      });

      const data = response.data.data;
      
      setManageLink(data.link)

    }

    if (subscription?.subscriptionCode) {
      fetchManageLink()
    }

    
  }, [subscription?.subscriptionCode])

  const [loadingStarter, setLoadingStarter] = useState(false)
  const [loadingPro, setLoadingPro] = useState(false)

  const [upgradeStarterLink, setUpgradeStarterLink] = useState("")
  const [upgradeProLink, setUpgradeProLink] = useState("")

  let baseURL;

  if (process.env.NODE_ENV === "development") {
    baseURL = "http://127.0.0.1:5001/barbapp-d4159/us-central1/"
  } else {
    baseURL = "https://us-central1-barbapp-d4159.cloudfunctions.net/"
  }

  useEffect(() => {

    const fetchUpgradeStarterLink = async () => {
      setLoadingStarter(true)
      const res = await axios.post(baseURL + "subscribe",
        {
          "data": {
            "email": email,
            "amount": "500",
            "plan": billingInterval === "monthly" ? "PLN_8tunwdrhmi3ii1c" : "PLN_uit8rbl1jglzoqh"
          }
        })

        console.log(res);

      setUpgradeStarterLink(res.data.data.authorization_url)
      if (res) {
        setLoadingStarter(false)
      }
    }

    const fetchUpgradeProLink = async () => {
      setLoadingPro(true)
      const res = await axios.post(baseURL + "subscribe",
        {
          "data": {
            "email": email,
            "amount": "500",
            "plan": billingInterval === "monthly" ? "PLN_ip5u2533od0laq2" : "PLN_u4qurvt7jk43afh"
          }
        })


      setUpgradeProLink(res.data.data.authorization_url)
      if (res) {
        setLoadingPro(false)
      }
    }

    if (email) fetchUpgradeStarterLink()
    if (email) fetchUpgradeProLink()
  }, [email, billingInterval]);

  return (
    <div className='sm:px-6 sm:p-0 p-4'>
      <div className='w-full'>


        {subscription?.billingState === "trialing" &&
          <div>
            <h2 className='text-lg font-semibold'>Trial</h2>
            <p>
              You're currently on a 30 days trial that will end on {moment(subscription?.trialEnd).format("MMMM Do YYYY")}. You have {differenceInCalendarDays(new Date(subscription?.trialEnd), new Date(Date.now()))} days left in your trial.
            </p>
            <p className='mt-2'>
              You can try out Wavier completely risk free! Feel free to add all your services, invite your staff members and share your link to customers.
            </p>
            <p className='mt-2'>
              If you need help with anything, reach out to us using the Support button at the lower right corner.
            </p>
          </div>
        }
        <div className='py-2 w-full flex justify-center items-center'>
          <label className="inline-flex items-center cursor-pointer">
            <span className={
              clsx(
                "mr-3 text-sm font-medium",
                billingInterval === "monthly" && "text-gray-900",
                billingInterval !== "monthly" && "text-gray-400"
              )
            }>Monthly billing</span>
            <input type="checkbox" value="" className="sr-only peer" checked={billingInterval === "yearly"} onChange={(event) => {
              if (event.target.checked) {
                setBillingInterval("yearly");
              } else {
                setBillingInterval("monthly");
              }
            }}></input>
            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span className={
              clsx(
                "ms-3 text-sm font-medium",
                billingInterval === "yearly" && "text-gray-900",
                billingInterval !== "yearly" && "text-gray-400"
              )
            }>
              <p>
                Yearly billing
              </p>
              <p className={"text-green-600"}>
                2 months free 🚀
              </p>
            </span>
          </label>
        </div>
        <div className={clsx(
          'flex gap-2 flex-col lg:flex-row mt-4 ',
          // "opacity-50"
        )} onClick={(event) => {
          // event.stopPropagation()
        }}>
          <div className={clsx(
            "w-full ",
            Object.keys(usersData[selectedShop]).length > MAX_USERS_STARTER && "opacity-50 pointer-events-none"
          )} >
            <PlanCard billingState={subscription?.billingState} billingInterval={billingInterval} upgradeLink={Object.keys(usersData[selectedShop]).length > MAX_USERS_STARTER ? "" : upgradeStarterLink} current={subscription?.plan} loading={loadingStarter} plan={'Starter'} features={[
              "Appointment management",
              "Unlimited service listing",
              "3 users",
              "Unique booking link"
            ]}
              priceGHS={billingInterval === "monthly" ? "GHS 300/mo" : "GHS 3000/yr"}
              priceUSD={billingInterval === "monthly" ? "$25/mo" : "$250/yr"}
              onUpgrade={async () => {

                if (upgradeStarterLink) {
                  analyticsEvents.clickUpgrade("plan_page");
                  posthog.capture("Click upgrade", {
                    Location: "Plans page"
                  })
                }
              }}

            />
            {Object.keys(usersData[selectedShop]).length > MAX_USERS_STARTER &&
              <p>You currently have {Object.keys(usersData[selectedShop]).length} users. You cannot subscribe to the Starter plan.</p>
            }
          </div>

          <div className={clsx(
            "w-full ",
            Object.keys(usersData[selectedShop]).length > MAX_USERS_PRO && "opacity-50 pointer-events-none"
          )} >
            <PlanCard billingState={subscription?.billingState} billingInterval={billingInterval} upgradeLink={Object.keys(usersData[selectedShop]).length > MAX_USERS_PRO ? "" : upgradeProLink} current={subscription?.plan} loading={loadingPro} plan={'Pro'} features={[
              "Appointment management",
              "Unlimited service listing",
              "10 users",
              "2 branches (coming soon)",
              "Unique booking link",
            ]}
              priceGHS={billingInterval === "monthly" ? "GHS 700/mo" : "GHS 7000/yr"}
              priceUSD={billingInterval === "monthly" ? "$50/mo" : "$500/yr"}
              onUpgrade={async () => {

                if (upgradeProLink) {
                  analyticsEvents.clickUpgrade("plan_page");
                  posthog.capture("Click upgrade", {
                    Location: "Plans page"
                  })
                }
              }} />

            {Object.keys(usersData[selectedShop]).length > MAX_USERS_PRO &&
              <div className='mt-2'>
                <p>You currently have {Object.keys(usersData[selectedShop]).length} users. You cannot subscribe to the Pro plan.</p>
                <p>Please <span><a className='underline text-blue-600' href='mailto:contact@wavier.io'>contact us</a></span> for a custom plan</p>
              </div>
            }
          </div>
        </div>
        <div className='py-4'>
          {subscription?.billingState === "non-renew" &&
            <div className="mt-2 text-md text-gray-700 hover:text-gray-900"
              onClick={() => {
              }}>
              You have unsubscribed. Your account will become inactive at the end of the subscription period.
            </div>
          }
          {subscription?.billingState !== 'trialing' && subscription?.billingState !== 'inactive' && manageLink &&
          <div className='py-2'>
            <div className='font-semibold'>
              Manage subscription
            </div>
            <p className='mt-2'>If you need to change your billing details, you can <span><a href={manageLink} target="_blank" className="mt-2 cursor-pointer underline text-blue-600 text-gray-700 hover:text-gray-900 hover:underline" rel="noreferrer"
            >
              manage your subscription
            </a></span>.</p>
            </div>
          }
        </div>

        <div className='pt-4'>
          <h2 className='font-semibold'>What is the difference between the Starter and Pro plan?</h2>
          <p className='text-sm text-gray-600'>We offer two plans to cater to your business needs: Starter and Pro. Our Starter plan is perfect for small businesses looking to get started with salon management. It provides essential features to help you streamline your operations. As your business grows and demands more advanced tools like marketing campaigns, analytics, and additional seats, our Pro plan steps in. Upgrade to Pro for enhanced capabilities and take your business to the next level with Wavier.</p>
        </div>




      </div >
    </div >
  )
}