import { createModel } from '@rematch/core'
import { RootState } from '../store'
import { RootModel } from './index'


interface IModals {
    upgradeModal: boolean,
    newCategoryModal: boolean,
    editCategoryModal: boolean,
    chooseCategoryModal: boolean,
    categoriesModal: boolean,
    isCreatingCategoryFromService: boolean,
    onboardingModal: boolean
}


export const Modals = createModel<RootModel>()({
    state: {
        upgradeModal: false,
        isCreatingCategoryFromService: false,
        onboardingModal: false
    } as IModals,
    reducers: {
        toggleUpgradeModal(state, payload) {
            return {
                ...state,
                upgradeModal: payload
            }
        },
        toggleNewCategoryModal(state, payload) {
            return {
                ...state,
                newCategoryModal: !state.newCategoryModal,
                isCreatingCategoryFromService: payload ? payload : false,
            }
        },
        toggleEditCategoryModal(state) {
            return {
                ...state,
                editCategoryModal: !state.editCategoryModal
            }
        },
        toggleChooseCategoryModal(state) {
            return {
                ...state,
                chooseCategoryModal: !state.chooseCategoryModal
            }
        },
        toggleCategoriesModal(state) {
            return {
                ...state,
                categoriesModal: !state.categoriesModal
            }
        },
        toggleOnboardingModal(state, payload) {
            return {
                ...state,
                onboardingModal: payload
            }
        },
        
    },
    effects: (dispatch) => ({
        // handle state changes with impure functions.
        // use async/await for async actions
        // async incrementAsync(payload, rootState) {
        //   await new Promise((resolve) => setTimeout(resolve, 1000));
        //   dispatch.count.increment(payload);
        // },
    }),
})

export const modalSelector = (state: RootState) => state.Modals
