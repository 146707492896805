import { createBrowserRouter, Navigate } from 'react-router-dom'
import SignInPage from '../pages/sign-in-page'
import BookingPage from '../pages/booking-page'
import ErrorPage from '../pages/error-page'
import { MainLayout } from '../components/layout/main-layout'
import { AuthLayout } from '../components/layout/auth-layout'
import HistoryPage from '../pages/history/HistoryPage'
import ShopProfile from '../pages/shop-data/ShopProfile'
import ShopServices from '../pages/shop-data/ShopServices'
import ShopProfessionals from '../pages/shop-data/ShopProfessionals'
import ShopAdmin from '../pages/shop-data/ShopAdmin'
import ShopPayments from '../pages/shop-data/ShopPayments'
import ShopDataPage from '../pages/shop-data/ShopDataPage'
import ProfessionalDataPage from '../pages/professional-data/ProfessionalDataPage'
import ProfessionalProfile from '../pages/professional-data/ProfessionalProfile'
import Help from '../pages/help/Help'
import Dashboard from '../pages/dashboard/Dashboard'
import AddShop from '../pages/add-shop/AddShop'
import PlansPage from '../pages/plans/PlansPage'
import PreferencesPage from '../pages/preferences/PreferencesPage'
import Customers from '../pages/customers/Customers'

export const createAppRoutes = (isAuthenticated: boolean) => {
    // console.log("Current auth state:", isAuthenticated)
    return createBrowserRouter([
        {
            path: 'app',
            element: isAuthenticated ? (
                <MainLayout />
            ) : (
                <Navigate to="/signin" />

            ),
            errorElement: <ErrorPage />,
            children: [
                {
                    path: 'dashboard',
                    element: <Dashboard />
                },
                {
                    path: 'booking',
                    element: <BookingPage />
                },
                {
                    path: 'history',
                    element: <HistoryPage />
                },
                {
                    path: 'add',
                    element: <AddShop />
                },
                {
                    path: 'customers',
                    element: <Customers />
                },
                {
                    path: 'settings',
                    element: <ShopDataPage />,
                    children: [
                        {
                            path: 'profile',
                            index: true,
                            element: <ShopProfile />
                        },
                        {
                            path: 'services',
                            element: <ShopServices />
                        },
                        {
                            path: 'users',
                            element: <ShopProfessionals />
                        },
                        {
                            path: 'admin',
                            element: <ShopAdmin />
                        },
                        {
                            path: 'policies',
                            element: <ShopPayments />
                        },
                        {
                            path: 'plans',
                            element: <PlansPage />
                        },
                        {
                            path: 'preferences',
                            element: <PreferencesPage />
                        },
                    ]
                },
                {
                    path: 'profile',
                    element: <ProfessionalProfile />
                },
                {
                    path: 'help',
                    element: <Help/>
                },
            ],
        },
        {
            path: '/',
            element: !isAuthenticated ? (
                <AuthLayout />
            ) : (
                <Navigate to="app/booking" />
                // <>
                //     {() => {
                //         return (
                //             <div>
                //                 is auth, should go to dash
                //             </div>
                //         )
                //     }}
                // </>
            ),
            errorElement: <ErrorPage />,
            children: [
                { path: 'signin', element: <SignInPage /> },
                {
                    path: '/',
                    element: <Navigate to="/signin" />,
                },
            ],
        },
    ])
}
