import React, { useEffect, useRef, useState } from 'react'
import ContentPage from '../../components/common/ContentPage'
import Section from '../../components/common/Section'
import TextInput from '../../components/input/TextInput'
import hours from "../../utils/day_hours.json"
import { fetchBusinessByID } from '../../api/booking'
import { useSelector } from 'react-redux'
import { updateShopProfile } from '../../utils/ShopProfileTasks'
import toast, { Toaster } from 'react-hot-toast'
import { shopSelector } from '../../store/models/shopModel'
import { showToast } from '../../utils/utilities'



const hoursArray: any[] = []

function ShopPayments() {


  const [loading, setLoading] = useState<boolean>(false)

  // refs to the different fields
  const windowRef = useRef<any>()
  const feeRef = useRef<any>()
  const latenessRef = useRef<any>()
  const numberRef = useRef<any>()
  const nameRef = useRef<any>()


  useEffect(() => {
    hours.map((hours) => {
      hoursArray.push({
        label: `${hours.standard_format} ${hours.time_of_day}`,
        value: hours.military_format
      })
    })
  }, [])

  // send the profile data to the backend
  const submit = async () => {
    setLoading(true)

    // const params = {
    //   "name": nameRef.current.getInputText(),
    //   "accountNumber": numberRef.current?.getInputText(),
    //   "bankCode": networkSelector?.value
    // }

    // console.log("dataToUpdate", params)
    // const paystackRes = await axios.post("https://us-central1-barbapp-d4159.cloudfunctions.net/savePaymentDetails", params);

    // console.log("res", paystackRes.data.data.recipient_code)

    var dataToUpdate: any = {
      // cancellationWindow: parseInt(windowRef.current?.getInputText()),
      // cancellationFee: parseInt(feeRef.current?.getInputText()),
      // recipientAccountNumber: numberRef.current?.getInputText(),
      allowedLateness: parseInt(latenessRef.current?.getInputText()),
      // paymentsEnabled: enablePayments,
      // bank: networkSelector?.value,
      // recipientID: paystackRes.data.data.recipient_code,
    }

    console.log("dataToUpdate", dataToUpdate)
    await updateShopProfile({ queryKey: ["shopID", selectedShop] }, dataToUpdate)
      .then(() => {
        setLoading(false)
        showToast("Your data has been saved", "success")
      }).catch((e) => {
        setLoading(false)
        showToast("We couldn't save your data, check your internet and try again", "error", 7000)
      })
  }

  const { selectedShop, shopsData } = useSelector(shopSelector)

  const [enablePayments, setEnablePayments] = useState<boolean>(false)

  // load existing details from backend
  useEffect(() => {
    console.log("Loading current details...")
    async function fetchCurrentShopData() {

      await fetchBusinessByID({ queryKey: ["shopID", selectedShop] })
        .then((data) => {
          windowRef.current?.setValue(data?.cancellationWindow ? data?.cancellationWindow : 0)
          feeRef.current?.setValue(data?.cancellationFee ? data?.cancellationFee : 0)
          numberRef.current?.setValue(data?.recipientAccountNumber ? data?.recipientAccountNumber : 0)
          latenessRef?.current?.setValue(data?.allowedLateness ? data?.allowedLateness : 0)
          setEnablePayments(data?.paymentsEnabled)
          if (data?.bank) {
            setNetworkSelector({
              label: data?.bank,
              value: data?.bank
            })
          }
        })
    }
    fetchCurrentShopData()

  }, [selectedShop])

  const [networkSelector, setNetworkSelector] = useState<any>(null)

  const networks = ["MTN", "Vodafone", "Airtel/Tigo"]

  return (
    <>

      <ContentPage loadingAction={loading} title={`Payment preferences & Policies (${shopsData[selectedShop].name})`} subtitle='Customize your payment preferences and cancellation policies' actionText='Save' showActionButton onClickAction={submit}>
        <div className='sm:px-6 sm:pt-6 p-4'>
          {/* <Section title='Enable payments' subtitle='Specify whether your clients need to pay upfront'>
            <div className='flex gap-2 px-4 accent-gray-900'>
              <input type={"checkbox"} />
              <div>
                Enable payments
              </div>
            </div>

          </Section> */}

          {/* <Section title='Payment Details' subtitle='Specify the mobile money number on which you will receive your money'>
            <div className=''>
              <div className='flex gap-2 accent-gray-900'>
                <input type={"checkbox"} checked={enablePayments} onClick={() => {
                  setEnablePayments(!enablePayments)
                }} />
                <div>
                  Enable payments
                </div>
              </div>
              <div className='text-sm text-gray-500'>
              When payments are disabled, businesses can still receive appointments. This option allows clients to secure their booking without immediate payment.
              </div>
              <div className='grid sm:grid-cols-2 gap-4 w-max mt-4'>
                <TextInput

                  ref={numberRef}
                  placeholder='055 000 0000'
                  showLabel
                  label="Phone number"
                />
                <div>
                  <div className='text-sm font-medium mb-2'>
                    Network provider
                  </div>

                  <Select placeholder="Select network" options={networks.map((category: any) => {
                    return {
                      label: category,
                      value: category
                    }
                  })} value={networkSelector} onChange={(change) => {
                    // setMadeChanges(true)
                    // console.log(change)
                    setNetworkSelector(change)
                  }} />
                </div>
                <TextInput
                  // hint='We recommend using your real name '
                  ref={nameRef}
                  placeholder="Enter the recipient's name"
                  showLabel
                  label="Name (Owner's name)"
                />
              </div>
            </div>

          </Section> */}

          <Section title='Cancellation policy' subtitle="Tailor cancellation rules to align with your business' needs and clients expectations.">
            <div className='grid sm:grid-cols-2 gap-4 w-max'>
              {/* <div className='flex items-center gap-2 relative'>
                <div className='w-full'>
                  <TextInput label='Cancellation window (hours)' type={"number"} onChange={() => { }} ref={windowRef} ></TextInput>
                </div>
                <div className='text-gray-400 h-full bottom-0 absolute right-2 bottom-2.5 flex justify-end flex-col'>
                  hour(s)
                </div>
              </div>

              <div className='flex items-center gap-2 relative'>
                <div className='w-full'>
                  <TextInput label='Cancellation Fee (%)' type={"number"} onChange={() => { }} ref={feeRef} ></TextInput>
                </div>
                <div className='text-gray-400 h-full bottom-0 absolute right-2 bottom-2.5 flex justify-end flex-col'>
                  %
                </div>
              </div> */}

              <div>
                <div className='flex items-center gap-2 relative'>
                  <div className='w-full'>
                    <TextInput hint='' label='Allowed lateness (minutes)' type={"number"} onChange={() => { }} ref={latenessRef} ></TextInput>
                  </div>
                  <div className='text-gray-400 h-full bottom-0 absolute right-2 bottom-2.5 flex justify-end flex-col'>
                    mins
                  </div>
                </div>
                <p className='w-3/4 text-gray-500 text-sm'>
                  After this period, the professional may mark the appointment as a no-show
                </p>
              </div>

            </div>
          </Section>


        </div>
        <Toaster />
      </ContentPage>
    </>
  )
}

export default ShopPayments