import React, { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { useIsMobile } from '../../hooks/useIsMobile'
import { shopSelector } from '../../store/models/shopModel'
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as Icon from "react-feather"
import { serviceSelector } from '../../store/models/Services'
import { professionalSelector } from '../../store/models/professionalsModel'
import { Button } from '../../components/button'
import { useNavigate } from 'react-router'
import { canAddService } from '../../utils/roles'
import { userSelector } from '../../store/models/userModel'
import BookingLink from '../../components/BookingLink'

function Dashboard() {

    const shop = useSelector(shopSelector)
    const services = useSelector(serviceSelector)
    const pros = useSelector(professionalSelector)
    const { roles } = useSelector(userSelector)

    const [servicesCount, setServicesCount] = useState<number>(0)
    const [prosCount, setprosCount] = useState<number>(0)

    const navigate = useNavigate()

    useEffect(() => {
        //    if(servicesCount && prosCount) {
        // console.log("Number of services:", Object.keys(services)?.length)
        // console.log("Number of pros:", pros.proIDs[shop.selectedShop]?.length)

        setServicesCount(Object.keys(services)?.length)
        setprosCount(pros.proIDs[shop.selectedShop]?.length)
        //    }

    }, [services, pros, shop.selectedShop, servicesCount, prosCount])

    const { isMobile } = useIsMobile()
    return (
        <div className="h-screen bg-gray-50">

            <main className="flex">
                {/* Main body */}
                <div className='flex flex-col w-full'>
                    <div className='flex'>
                        <div className="w-full h-screen pt-4 border-gray-100 flex flex-col ">
                            <div className="py-2 px-6">
                                <p className={`mb-2 font-semibold leading-6 ${isMobile ? "text-xl" : "text-2xl"}`}>
                                    {"Collect appointments"}
                                </p>
                            </div>

                            <BookingLink servicesCount={servicesCount} prosCount={prosCount} businessID={shop.selectedShop}/>

                        </div>
                    </div>
                </div>

            </main >
            <Toaster />
        </div >
    )
}

export default Dashboard