import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import toast, { ToastPosition, Toaster } from 'react-hot-toast'
import { storage } from '../config/firebase.config'
import Compressor from 'compressorjs';
import axios from 'axios';

export const formatNumber = (number: string) => {
    const pattern = /^(0(\d{9}))$|^(\+?233)(\d{9})$/

    const matches = number.match(pattern)
    if (matches) {
        const plus233Beginning = '+233' + matches[4]
        const two33Beginning = '233' + matches[4]
        const resultArray = [plus233Beginning, two33Beginning]
        return resultArray
    } else {
        return 'Invalid phone number'
    }
}

export const showToast = (text: string, type: 'success' | 'error' | "custom", duration?: number, position?: ToastPosition, icon?: any) => {
    if (type === 'success') {
        toast.success(text, {
            duration: duration,
            position: position
        })
        
    } else if (type === 'error') {
        toast.error(text, {
            duration: duration,
            position: position
        })
    } else if (type === 'custom') {
        toast.error(text, {
            duration: duration,
            position: position,
            icon
        })
    } 
}

export const uploadFile = async (file: any, url: string) => {
    if (!file) return undefined;

    const fileRef = ref(storage, url);
    const snapshot = await uploadBytes(fileRef, file)
    const firebaseURL = getDownloadURL(snapshot.ref)
    return firebaseURL

};

export const uploadImage = async (file: any, url: string) => {
    if (!file) return;

    var firebaseURL: any ="";

    const getLink = async (url: any) => {
        firebaseURL = url
        console.log("in func", firebaseURL)
    }

    const promise = new Promise((res, rej) => {
        res(firebaseURL)
    })

    new Compressor(file, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        convertTypes: ['image/png'],
        convertSize: 2000000,
        success: async (compressedResult) => {
            // compressedResult has the compressed file.
            // Use the compressed file to upload the images to your server.        
            getLink(await uploadFile(compressedResult, url))
        },
    });

    // console.log("in upload", firebaseURL)
    return firebaseURL
};

export const notifySlack = async (text: string, url: string) => {
    let data = JSON.stringify({
        text,
        url,
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://us-central1-barbapp-d4159.cloudfunctions.net/notifySlack',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    axios.request(config)
        .then((response) => {
            console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
            console.log(error);
        });
}
