import React, { useState } from 'react'
import OnboardingNavigation from './OnboardingNavigation'
import BookingLink from '../BookingLink'
import { useSelector } from 'react-redux'
import { shopSelector } from '../../store/models/shopModel'
import { startTrial } from '../../api/api'
import { showToast } from '../../utils/utilities'


interface IOnboardingDone {
    onNext?: () => void,
    onPrevious?: () => void,
    onSkip?: () => void
}

function OnboardingDone({
    onNext,
    onPrevious,
    onSkip,
}: IOnboardingDone) {

    const { selectedShop } = useSelector(shopSelector)


    const [loading, setLoading] = useState(false)

    const finishOnboarding = async () => {
        try {
            setLoading(true);
            await startTrial(selectedShop)
            showToast("Welcome to Wavier!", "success")
            onNext && onNext()
        } catch (e) {
            setLoading(false);
            console.log("Couldn't finish onboarding:", e)
            showToast("Something went wrong. Please try again in a few seconds", "error")
        }
           
    }

    return (
        <div>

            <div className='py-4 flex flex-row justify-between items-start gap-2 w-full'>
                <BookingLink servicesCount={1} prosCount={1} businessID={selectedShop} />
            </div>

            <div className=''>


                <OnboardingNavigation
                    canNext={true}
                    canPrevious={true}
                    showNext={true}
                    showPrevious={false}
                    showSkip={false}
                    canSkip={false}
                    onNext={finishOnboarding}
                    onPrevious={onPrevious}
                    onSkip={onSkip}
                    loading={loading}
                    nextText='Okay!' />
            </div>
        </div>
    )
}


export default OnboardingDone