import { doc, getDoc, getDocs, collection, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { firestoreDb } from "../config/firebase.config";

export const fetchRoles = async (businessID: string) => {
    const usersRoles: any = []
    const users = await getDocs(collection(firestoreDb, "Businesses", businessID, "Users"))

    users.forEach((doc) => {
        // console.log(doc.id, " => ", doc.data())
        usersRoles.push(doc.data())
    })

    // console.log("Fetched roles", usersRoles)
    return usersRoles;
}

export const fetchUserRoles = async (businessID: string, userID: string) => {
    const usersRoles: any = []
    const users = await getDocs(collection(firestoreDb, "Businesses", businessID, "Users"))

    users.forEach((doc) => {
        // console.log(doc.id, " => ", doc.data())
        usersRoles.push(doc.data())
    })

    const roles = usersRoles.filter((role: any) => {
        return role.id === userID;
    })[0];
    // console.log("roles of " + userID + " in " + businessID +":", roles);
    return roles;
}

export const fetchUsers = async (businessID: string) => {
    const users: any = []
    const usersQuery = await getDocs(collection(firestoreDb, "Businesses", businessID, "Users"))

    usersQuery.forEach((doc) => {
        // console.log(doc.id, " => ", doc.data())
        users.push(doc.data())
    })

    // console.log("Fetched users for business " + businessID + ":", users)
    return users;
}

export const fetchCategories = async (businessID: string) => {
    const categories: any = []
    const categoriesQuery = await getDocs(collection(firestoreDb, "Businesses", businessID, "Categories"))

    categoriesQuery.forEach((doc) => {
        // console.log(doc.id, " => ", doc.data())
        categories.push(doc.data())
    })

    // console.log("Fetched users for business " + businessID + ":", users)
    return categories;
}

export const saveCategory = async (businessID: string, name: string, description: string) => {

    const newCategoryRef = doc(collection(firestoreDb, "Businesses", businessID, "Categories"))

    await setDoc(newCategoryRef, {
        id: newCategoryRef.id,
        name,
        description,
        createdAt: new Date().toISOString(),
    })

    return newCategoryRef.id;
}

export const deleteCategory = async (businessID: string, categoryID: string) => {

    const categoryRef = doc(firestoreDb, "Businesses", businessID, "Categories", categoryID)

    await deleteDoc(categoryRef);

    return categoryRef.id;
}

export const editCategory = async (businessID: string, categoryID: string, name: string, description: string) => {

    const categoryRef = doc(firestoreDb, "Businesses", businessID, "Categories", categoryID)

    await updateDoc(categoryRef, {
        name,
        description
    })

    return categoryRef.id;
}

export const setOnboardingStep = async (businessID: string, step: number, state: { completed: boolean, description: string, skip?: boolean }) => {

    const onboardingRef = doc(firestoreDb, "Businesses", businessID, "Settings", "onboarding");

    const onboardingData = await getDoc(onboardingRef);
    const data = onboardingData.data();
    if (data) {
        await updateDoc(onboardingRef, {
            ...data,
            ["step" + step]: {
                ...data?.["step" + step],
                ...state
            }
        })
    }

    return state;
}

export const setAccountActivated = async (businessID: string) => {

    const onboardingRef = doc(firestoreDb, "Businesses", businessID, "Settings", "onboarding");
    const businessgRef = doc(firestoreDb, "Businesses", businessID);

    await updateDoc(onboardingRef, {
        activated: true
    })
    await updateDoc(businessgRef, {
        isLive: true
    })

    return {
        activated: true,
        isLive: true
    };
}

export const startTrial = async (businessID: string) => {

    const subRef = doc(firestoreDb, "Businesses", businessID, "Settings", "subscription");

    const trialEnd = new Date(new Date(Date.now()).setMonth(new Date().getMonth() + 1)).toISOString();
    console.log(trialEnd)
    await updateDoc(subRef, {
        trialStart: new Date(Date.now()).toISOString(),
        trialEnd: trialEnd.toString(),
        nextBilling: trialEnd.toString(),
        billingState: "trialing",
    })


    return {
        activated: true,
        isLive: true
    };
}

export const fetchOnboarding = async (businessID: string) => {

    const onboardingRef = doc(firestoreDb, "Businesses", businessID, "Settings", "onboarding");

    const onboardingData = await getDoc(onboardingRef);
    const data = onboardingData.data();
    return data;
}

export const fetchServices = async (businessID: string) => {
    // const usersRoles: any = []
    // const users = await getDocs(collection(firestoreDb, "Businesses", businessID, "Users"))

    // users.forEach((doc) => {
    //     // console.log(doc.id, " => ", doc.data())
    //     usersRoles.push(doc.data())
    // })

    // console.log("Fetched roles", usersRoles)
    // return usersRoles;
}

