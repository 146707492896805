import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { modalSelector } from '../store/models/modals';
import * as Icon from "react-feather";
import clsx from 'clsx';

function UpgradeButton({color}: {color?: "purple" | "white"}) {

    const { upgradeModal } = useSelector(modalSelector)
    const dispatch = useDispatch();

  return (
    <div onClick={() => {
        dispatch.Modals.toggleUpgradeModal(true);
      }} className={clsx(
        "flex gap-1 justify-start items-center cursor-pointer transition-all",
        !color || color === "purple" && " text-purple-600 hover:text-purple-900",
        color === "white" && " text-white hover:text-purple-300",
      )}>
        <Icon.Zap size={18} />
        <a className='font-semibold underline'>
          {`Upgrade`}
        </a>

      </div>
  )
}

export default UpgradeButton