import React, { ReactNode } from 'react'

interface IOnboardingContent {
    children: ReactNode,
    title: string,
}

function OnboardingContent({
    children,
    title
}: IOnboardingContent) {
    return (
        <div>
            <div className='text-xl font-semibold'>
                {title}
            </div>
            <div>
                {children}
            </div>
        </div>
    )
}

export default OnboardingContent