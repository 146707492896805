import React from 'react'
import ContentPage from '../../components/common/ContentPage'
import { useIsMobile } from '../../hooks/useIsMobile'
import { Toaster } from 'react-hot-toast'
import { Button } from '../../components/button'

function Customers() {

    const { isMobile } = useIsMobile()
    return (
        <div>
            <div className="h-screen bg-gray-50">


                <main className="flex">
                    {/* Main body */}
                    <div className='flex flex-col w-full'>
                        <div className='flex'>
                            <div className="w-full h-screen pt-4 border-gray-100 flex flex-col ">
                                <div className="py-2 px-6">
                                    <p className={`mb-2 text-base font-semibold leading-6 ${isMobile ? "text-xl" : "text-2xl"}`}>
                                        {"Customers"}
                                    </p>
                                </div>


                                <div className='bg-white mx-2 mt-2 py-2 px-4 rounded-lg space-y-2'>
                                    <p><strong>Coming Soon: Customer Management Feature</strong></p>

                                    <p>We're excited to announce that we have an amazing new feature in the pipeline - Customer Management!</p>

                                    <p>With this feature, you'll be able to streamline your customer interactions, keep track of important details, and provide an even better experience. Stay tuned as we work diligently to bring this functionality to you.</p>

                                    <p>Your input matters! If you're eager to see this feature come to life, let us know by casting your vote on our <a className='font-semibold text-blue-500 underline' href="https://wavier.canny.io/feature-requests/p/customers-management">Feature Request Board</a>. Your feedback guides our development priorities, and we're here to build the tools that matter most to you.</p>

                                    <p>Thank you for being a part of our journey towards making your experience with Wavier even more exceptional.</p>

                                    <p>Stay tuned, and let's shape the future together!</p>

                                    <p className='pt-2'><a href="https://wavier.canny.io/feature-requests/p/customers-management">
                                        <Button hug disabled={false} onClick={() => {}} size={'sm'} >
                                            Go to the Feature Request Board
                                        </Button>
                                    </a></p>

                                </div>

                            </div>
                        </div>
                    </div>

                </main >

                <Toaster />
            </div >
        </div>
    )
}

export default Customers