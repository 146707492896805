import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useReactTable, createColumnHelper, getCoreRowModel, flexRender, getSortedRowModel, SortingState, getFilteredRowModel } from '@tanstack/react-table'
import moment from 'moment'
import TableHeader from './table/TableHeader'
import AppointmentStatusLabel from './AppointmentStatusLabel'
import { useSelector } from 'react-redux'
import { AppPageSelector } from '../store/models/appPage'
import { analyticsEvents } from '../utils/Events'
import { usePostHog } from 'posthog-js/react'

type Appointment = {
    client: any,
    date: Date,
    time: string,
    service: any,
    status: string,
}

type TAppointmentTableComponent = {
    data: any,
    currentBooking?: () => number,
    filter?: string
    columnFilter?: string
    type: string,
}

const columHelper = createColumnHelper<Appointment>()

const tableColumns = [
    columHelper.accessor("client", {
        cell: info => <div className='font-medium text-sm text-gray-900'>{info.getValue()}</div>,
        header: () => <TableHeader>Client</TableHeader>,
        footer: info => info.column.id,
    }),
    columHelper.accessor("date", {
        cell: info => <div className='text-xs text-gray-500'>{moment(info.getValue()).format("MMMM Do YYYY")}</div>,
        header: (header) => <div onClick={header.column.getToggleSortingHandler()}>
            <TableHeader showSorting sortingOder={header.column.getIsSorted()}>Date</TableHeader>
        </div>,
        footer: info => info.column.id,
        sortingFn: "datetime",
        enableSorting: true,
        // sortDescFirst: true,
        invertSorting: true
    }),
    columHelper.accessor("time", {
        cell: info => <div className='text-xs text-gray-500'>{info.getValue()}</div>,
        header: () => <TableHeader>Time</TableHeader>,
        footer: info => info.column.id,
    }),
    columHelper.accessor("service", {
        cell: info => <div className='text-sm text-gray-900'>{info.getValue()}</div>,
        header: () => <TableHeader>Service</TableHeader>,
        footer: info => info.column.id,
    }),
    columHelper.accessor("status", {
        cell: info => <AppointmentStatusLabel status={info.getValue()} />,
        header: () => <TableHeader>Status</TableHeader>,
        footer: info => info.column.id,

    }),
]

function AppointmentTable({ data, filter, columnFilter, type }: TAppointmentTableComponent, ref: any) {
    const posthog = usePostHog()
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);
    const { page } = useSelector(AppPageSelector)
    const [sorting, setSorting] = useState<SortingState>([{ id: 'date', desc: true }]);
    const [filtering, setFiltering] = useState<string | undefined>("")
    const [columnFilters, setColumnFilters] = useState<any>([])

    // useEffect(() => {
    //     setColumnFilters(columnFilter)
    // }, [columnFilter])
    useEffect(() => {
        // console.log(filter)
        setFiltering(filter?.toLowerCase())
    }, [filter])

    useEffect(() => {
        if (page === "/app/booking") {
            setSorting([{ id: 'date', desc: true }])
        } else if (page === "/app/history") {
            setSorting([{ id: 'date', desc: false }])
        }
    }, [page])

    const actualData: Appointment[] | any = [];
    const [tableData, setTableData] = useState<Appointment[]>(actualData)
    // filter out the data to keep only what is necessary  

    if (data) {
        data?.map((dataItem: any, index: number) => {
            actualData.push({
                client: dataItem.user?.name,
                // date: moment(dataItem.isoDate).format("MMMM Do YYYY"),
                date: new Date(dataItem.isoDate),
                time: `${moment(dataItem.isoDate).format("LT")} - ${moment(dataItem.endTime).format("LT")}`,
                // service: dataItem?.services ? dataItem?.services.reduce((a: any, b: any) => `${a.Name}, ${b.Name}`).toString() : "[None]",
                service: dataItem?.services ? dataItem?.services.map((service: any, index: number) => {
                    if (index < 2) {
                        return service.name
                    }

                    if (index === dataItem?.services.length - 1) {
                        return `+${dataItem?.services.length - 2}`
                    }

                    if (index >= 2) return "";
                }).reduce((a: string, b: string) => `${a}, ${b}`) : "[None]",

                status: dataItem.status ? dataItem.status : "accepted", //if there's no status attached (older versions of mobile app) simply mark it as accepted by default
            })
        })


    }

    // create the table using the library's hook
    const table = useReactTable({

        data: tableData,
        columns: tableColumns,
        getCoreRowModel: getCoreRowModel(),
        enableRowSelection: true,
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            sorting,
            globalFilter: filtering,
            // columnFilters: columnFilters
        },
        onSortingChange: setSorting,
        onGlobalFilterChange: setFiltering,
        // onColumnFiltersChange: setColumnFilters,
    })

    // set the first row of the table as selected by default
    useEffect(() => {
        if (actualData) {
            setTableData(actualData)
            table.getRowModel().rows.map((row) => {
                if (row.index === 0) {
                    table.toggleAllRowsSelected(false)
                    row.toggleSelected(true)
                    setSelectedRowIndex(row.index)
                }
            })

        }
    }, [data]) //don't listen to the debugger. Adding the full deps array create an infinite loop
    // pass up the current index of the data to show

    // make the selected index available outside
    // const numberOfEntries = table.getFilteredRowModel().rows.length;
    useImperativeHandle(ref, () => {
        return {
            getSelectedBookingIndex: () => selectedRowIndex,
            getNumberOfEntries: () => table.getFilteredRowModel().rows.length,
        }
    }, [selectedRowIndex, table.getFilteredRowModel().rows.length, table]);

    return (
        <div className='flex w-full '>
            <table className='w-full border-collapse overflow-auto'>
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th key={header.id} className='p-0'>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>

                <tbody>
                    {table.getRowModel().rows.map(row => (
                        <tr key={row.id} onClick={() => {
                            table.toggleAllRowsSelected(false)
                            row.toggleSelected()
                            setSelectedRowIndex(row.index)
                            if(type === "upcoming") {
                                analyticsEvents.appointmentViewUpcoming();
                                posthog.capture("View upcoming")
                            } else if(type === "history") {
                                analyticsEvents.appointmentViewHistory()
                                posthog.capture("View history")
                            }

                        }} className={`transition ${row.getIsSelected() && ("bg-gray-200 hover:bg-gray-200")} hover:bg-gray-100 cursor-pointer`}>
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id} className='p-0'>
                                    <div className='h-16 flex items-center px-4 border-2 border-[#EAECF0]/[.0] border-b-gray-100'>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </div>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default forwardRef(AppointmentTable)