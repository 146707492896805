import moment from 'moment'
import AppointmentStatusLabel from './AppointmentStatusLabel'

interface IAppointmentItemMobile {
    client: string,
    service: any,
    startTime: string,
    endTime: string,
    status: string,
    onClick: () => void,
}

function AppointmentItemMobile({
    client,
    service,
    startTime,
    endTime,
    status,
    onClick
}: IAppointmentItemMobile) {

    return (
        <div className='transition py-2 px-4 hover:bg-gray-200 border-b-2 border-gray-50' onClick={() => {
            onClick()
        }}>
            <div className='text-xs text-gray-500'>
                {moment(startTime).format("DDMMYYY") === moment().format("DDMMYYY") ?
                    <>
                        <div>
                            Today
                        </div>
                    </>
                    :
                    <>
                        {moment(startTime).format("DDMMYYY") === moment().add(1, 'd').format("DDMMYYY") ?
                            <>
                                <div>
                                    Tomorrow
                                </div>
                            </>
                            :
                            <>
                                {moment(startTime).format("MMMM Do YYYY")}
                            </>}
                    </>}
            </div>
            <div className='flex justify-between items-center '>

                <div className='flex gap-2'>
                    <div className='gap-2'>
                        <div className='text-md font-medium'>
                            {client}
                        </div>
                        <div className='text-sm text-gray-500'>
                            {service}
                        </div>
                    </div>

                </div>
                <div className='flex'>
                    <div className='flex gap-2'>
                        <div className='flex flex-col items-end'>
                            <div className='text-xs text-gray-500'>
                                From
                            </div>
                            <div className='text-xs text-gray-500'>
                                To
                            </div>
                        </div>
                        <div className='gap-2'>
                            <div className='text-xs text-gray-900'>
                                {moment(startTime).format("LT")}
                            </div>
                            <div className='text-xs text-gray-900'>
                                {moment(endTime).format("LT")}
                            </div>
                        </div>
                        <div className='flex gap-2'>


                        </div>
                    </div>
                    <div>
                        <AppointmentStatusLabel status={status} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppointmentItemMobile