import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Barrier from "../../assets/svg/in-progress-1.svg"
import AddService from '../../components/AddService'
import ContentPage from '../../components/common/ContentPage'
import DeleteService from '../../components/DeleteService'
import ServiceDetails from '../../components/ServiceDetails'
import ServicesTable from '../../components/ServicesTable'
import { useIsMobile } from '../../hooks/useIsMobile'
import { serviceSelector } from '../../store/models/Services'
import { shopSelector } from '../../store/models/shopModel'
import { Oval } from 'react-loader-spinner'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { professionalSelector } from '../../store/models/professionalsModel'
import EmptyStateNotice from '../../components/EmptyStateNotice'
import { canAddService } from '../../utils/roles'
import { userSelector } from '../../store/models/userModel'

function ShopServices() {
  const shop = useSelector(shopSelector)
  const services = useSelector(serviceSelector)
  const [servicesArray, setservicesArray] = useState([])
  const [categories, setCategories] = useState<string[]>([])
  const [selectedService, setSelectedService] = useState(null)
  const { isMobile } = useIsMobile()
  const [pagePart, setPagePart] = useState<"services" | "details" | "add" | "remove">("services")
  const { shopsData, selectedShop } = useSelector(shopSelector);

  let [searchParams, setSearchParams] = useSearchParams();

  const pros = useSelector(professionalSelector)
  const { roles } = useSelector(userSelector)

  const [servicesCount, setServicesCount] = useState<number>(0)
  const [prosCount, setprosCount] = useState<number>(0)

  const navigate = useNavigate()

  const dispatch = useDispatch()

  useEffect(() => {
    //    if(servicesCount && prosCount) {
    // console.log("Number of services:", Object.keys(services)?.length)
    // console.log("Number of pros:", pros.proIDs[shop.selectedShop]?.length)

    setServicesCount(Object.keys(services)?.map((category: any) => {
      return services[category].length
    }).reduce((acc: number, curr: number) => acc + curr, 0));
    setprosCount(pros.proIDs[shop.selectedShop]?.length)
    //    }

  }, [services, pros, shop.selectedShop, servicesCount, prosCount])

  // useEffect(() => {
  //   console.log(searchParams.get("action"))
  // }, [searchParams])

  useEffect(() => {
    const allServices: any = []
    const categoriesArray: string[] = []
    Object.keys(services).map((category) => {
      services[category].map((service: any) => {
        allServices.push({
          ...service,
          category
        })
      })

      setservicesArray(allServices)

      categoriesArray.push(category)
    })
    // console.log(categoriesArray)
    setCategories(categoriesArray)
  }, [services])

  useEffect(() => {
    setSelectedService(servicesArray[0])
  }, [servicesArray])

  const selectedServiceIndexRef = useRef<any>()

  return (


    <div className='flex grow w-full h-full'>
      <div className={`${(isMobile && pagePart === "services") ? "" : "hidden"} md:block flex-1 bg-green-400`}>
        {!shopsData ?
          <div className='flex flex-1 grow justify-center '>
            <Oval color='#343434' secondaryColor='#7D7D7D' width={40} />
          </div> :

          <ContentPage title={`Services (${shopsData[selectedShop]?.name})`} subtitle="Add, edit & remove services in your business" actionText={'Add a service'} secondaryActionText={'Categories'} showActionButton={(prosCount > 0) && canAddService(roles)} showSecondaryActionButton onClickAction={() => {
            setPagePart("add")
            setSearchParams(params => {
              params.set("action", "add")
              return params
            })
          }}

            onClickSecondaryAction={() => {
              dispatch.Modals.toggleCategoriesModal();
            }}
          >
            {/* <div className='sm:px-6 pt-6 p-4'>
            <hr className='mb-2'></hr>
          </div> */}
            <div className='mt-4'>
              <div >
                {(servicesCount > 0 && prosCount > 0) &&
                  <ServicesTable data={servicesArray} ref={selectedServiceIndexRef} onRowClick={() => {
                    setTimeout(() => {
                      //wait a little for state to change, that's a wildcard
                      selectedServiceIndexRef.current && setSelectedService(servicesArray[selectedServiceIndexRef.current.getSelectedServiceIndex()])
                      setPagePart("details")
                      setSearchParams(params => {
                        params.delete("action")
                        return params
                      })
                    }, 100)
                  }} />
                }
                {(servicesCount === 0 && (prosCount === 0)) &&
                  <EmptyStateNotice icon='x'
                    headline='You have not added your staff yet'
                    body='You need to add your staff first before adding your first service.'
                    showAction
                    action='Add staff'
                    onPressAction={() => {
                      navigate("/app/settings/users?action=add")
                    }}
                  />
                }
                {((servicesCount === 0) && (prosCount > 0)) &&
                  <EmptyStateNotice icon='x'
                    headline='No service added yet'
                    body='Add services to let your customers book the ones they like'
                    action='Add a service'
                    showAction = {canAddService(roles)}
                    onPressAction={() => {
                      setPagePart("add")
                      setSearchParams(params => {
                        params.set("action", "add")
                        return params
                      })
                    }}
                  />
                }

              </div>
            </div>
          </ContentPage>
        }
      </div>
      {pagePart === "details" &&
        <>

          <div className={`md:ml-4 flex ${(isMobile && pagePart === "details") ? "" : "hidden"} lg:block w-full md:w-[24rem]`}>
            <ServiceDetails categories={categories} onClose={() => {
              setPagePart("services")
              setSearchParams(params => {
                params.delete("action")
                return params
              })
            }} selectedService={selectedService} loadingDetails={false} onPressDelete={() => {
              setPagePart("remove")
              setSearchParams(params => {
                params.delete("action")
                return params
              })
            }} />
          </div>

        </>
      }
      {(pagePart === "add" || searchParams.get("action") === "add") && (prosCount > 0) &&
        <>

          <div className={`md:ml-4 flex ${(isMobile && pagePart === "add") ? "" : "hidden"} lg:block w-full md:w-[24rem]`}>
            <AddService categories={categories} onClose={() => {
              setPagePart("services")
              setSearchParams(params => {
                params.delete("action")
                return params
              })
            }} loadingDetails={false} onAddService={() => setPagePart("services")} />
          </div>

        </>
      }
      {pagePart === "remove" &&
        <>

          <div className={`md:ml-4 flex ${(isMobile && pagePart === "remove") ? "" : "hidden"} lg:block w-full md:w-[24rem]`}>
            <DeleteService service={selectedService} onClose={() => {
              setPagePart("services")
              setSearchParams(params => {
                params.delete("action")
                return params
              })
            }} loadingDetails={false} onDelete={() => setPagePart("services")} onCancel={() => setPagePart("details")} />
          </div>

        </>
      }

    </div>
  )
}

export default ShopServices