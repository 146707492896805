import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { auth, firestoreDb } from '../../config/firebase.config'
import { Toaster } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../components/button'
import { Business, Owner, Staff, User } from '../../types'
import TextInput, { TextInputElement } from '../../components/input/TextInput'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import PhoneInput from 'react-phone-input-2'
import '../../components/auth/index.css'
import { notifySlack, showToast } from '../../utils/utilities'
import { analyticsEvents } from '../../utils/Events'
import * as Icon from "react-feather"
import { Tooltip } from 'react-tooltip'
import { Manager } from 'react-popper'
import { userSelector } from '../../store/models/userModel'
import screenshot from "../../assets/images/signin_screenshot.webp"
import barbershop from "../../assets/images/barbershop.webp"
import { usePostHog } from 'posthog-js/react'
import axios, { AxiosInstance } from 'axios'
import countries from "../../utils/countries.json"
import currencies from "../../utils/currencies.json"
import { GoogleAuthProvider } from "firebase/auth";
import GoogleSSO from '../../components/GoogleSSO'
import {
    isValidPhoneNumber,
    isPossiblePhoneNumber,
    parsePhoneNumberFromString as parsePhoneNumber
} from "libphonenumber-js";
import { Oval } from 'react-loader-spinner'



const SIGNUP_SLACK_WEBHOOK_URL = 'https://hooks.slack.com/services/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';
// const SUB_SLACK_WEBHOOK_URL = 'https://hooks.slack.com/services/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';

enum AuthActions {
    SIGN_IN,
    SIGN_UP,
}

const SignInPage = () => {

    const provider = new GoogleAuthProvider();

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const posthog = usePostHog()

    const [searchParams, setSearchParams] = useSearchParams();

    const { id } = useSelector(userSelector)

    const [actions, setActions] = useState<AuthActions>(AuthActions.SIGN_IN)


    const [loading, setLoading] = useState<boolean>(false)

    const [phoneNumber, setPhoneNumber] = useState<string>('') //user phone number
    const [businessPhoneNumber, setBusinessPhoneNumber] = useState<string>('') //business phone number

    /* ------------------- // firebase sign in/sign up errors ------------------- */
    const [emailAlreadyUsed, setEmailAlreadyUsed] = useState(false)
    const [invalidPassword, setInvalidPassword] = useState(false)
    const [weakPassword, setWeakPassword] = useState(false)
    const [userNotFound, setUserNotFound] = useState(false)
    const [invalidEmail, setInvalidEmail] = useState(false)

    /* ---------------------------- // reset password --------------------------- */
    const [reset, setReset] = useState(false)
    const [resetError, setResetError] = useState(false)

    const [businessDetails, setBusinessDetails] = useState<Business>()
    const [businessCurrency, setBusinessCurrency] = useState<any>(currencies.USD)

    var nameRef = useRef<TextInputElement | null>(null)
    var emailRef = useRef<TextInputElement | null>(null)
    var passwordRef = useRef<TextInputElement | null>(null)

    const [userName, setUserName] = useState<any>("")
    const [userEmail, setUserEmail] = useState<any>("")
    const [userPassword, setUserPassword] = useState<any>("")

    var businessNameRef = useRef<TextInputElement | null>(null)
    var businessEmailRef = useRef<TextInputElement | null>(null)

    const [businessName, setBusinessName] = useState<any>("")
    const [businessEmail, setBusinessEmail] = useState<any>("")

    const [showPassword, setShowPassword] = useState<boolean>(false)

    const [country, setCountry] = useState<any>({});
    const [countryCode, setCountryCode] = useState<string>("GH");
    const [businessCountryCode, setBusinessCountryCode] = useState<string>("GH");
    const [isUserPhoneValid, setIsUserPhoneValid] = useState<any>(false);
    const [isBusinessPhoneValid, setIsBusinessPhoneValid] = useState<any>(false);

    let client: AxiosInstance;

    if (process.env.NODE_ENV === "development") {
        client = axios.create({
            baseURL: "http://127.0.0.1:5001/barbapp-d4159/us-central1/"
        })
    } else {
        client = axios.create({
            baseURL: "https://us-central1-barbapp-d4159.cloudfunctions.net/"
        })
    }


    useEffect(() => {
        // console.log(searchParams.get("country"));
        const countryData = countries.countries.country.filter((country) => country.countryName === searchParams.get("country"))
        const currencyCode = countryData[0]?.currencyCode;

        const currency = Object.keys(currencies).map((currency: string) => {
            // @ts-expect-error
            return currencies[currency]
        })
            .filter((currency) => currency.code === currencyCode)[0];


        //   console.log(currency ? currency : currencies.USD)
        setBusinessCurrency(currency ? currency : currencies.USD)
        // console.log(countryData)
    }, [searchParams])


    const signUp = () => {
        setLoading(true)

        const email = emailRef.current?.getInputText()
        const password = passwordRef.current?.getInputText()

        let accountDetails: User;
        accountDetails = {
            id: "",
            businesses: [],
            profilePic: "",
            createdAt: new Date().toISOString(),
            phone: phoneNumber,
            name: nameRef.current?.getInputText() ? nameRef.current?.getInputText() : "",
            userName: nameRef.current?.getInputText() ? nameRef.current?.getInputText() : "",
            email: emailRef.current?.getInputText() ? emailRef.current?.getInputText() : "",
        }



        // @ts-expect-error
        createUserWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                // Signed in 
                const user = userCredential.user;

                const usersRef = doc(firestoreDb, `Users/${user.uid}`)

                const userData: any = {
                    ...accountDetails,
                    id: user.uid,
                }

                // console.log(userData)

                setDoc(usersRef, userData)
                    .then(() => {

                        dispatch.User.saveUser(userData)
                        dispatch.Services.saveServices([])
                        // @ts-ignore

                        posthog.capture("Signup", {
                            Provider: "Email & Password",
                            Plan: searchParams.get('plan') ? searchParams.get('plan') : "",
                            $set: {
                                name: accountDetails.name,
                                email: email
                            }
                        })

                        const params = new URLSearchParams(searchParams)
                        params.set("step", "2");
                        setSearchParams(params)

                        setLoading(false)

                        if (process.env.NODE_ENV === "production") {
                            notifySlack(`New user signup: ${userData?.name} ${userData?.email} - ${userData?.phone} (Unique ID: ${user.uid})`,
                                SIGNUP_SLACK_WEBHOOK_URL);
                        }
                    })

                showToast("Your account was succesfully created!", "success");
                return user;
            })
            .then((user) => {
                if (invitation) {
                    client.post("/acceptInvitation", {
                        "inviter": inviteData.inviter,
                        "inviteID": inviteData.id,
                        "businessID": inviteData.businessID,
                        "roles": inviteData.roles,
                        "userID": user.uid
                    })
                }
            })
            .then((response: any) => {
                if (invitation) {
                    showToast(`You have joined ${inviteData.businessName}!`, "success")
                }

            })
            .catch((error) => {
                setLoading(false)
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
                console.log(`Error code: ${errorCode}, error message: ${errorMessage}`)

                if (errorCode === "auth/weak-password") {
                    setWeakPassword(true)
                }
                if (errorCode === "auth/email-already-in-use") {
                    setEmailAlreadyUsed(true)
                }
                if (errorCode === "auth/invalid-email") {
                    setInvalidEmail(true)
                }
            });
    }

    const setUpBusiness = async () => {
        try {
            setLoading(true)
            let host;
            if (process.env.NODE_ENV === "development") {
                host = "http://127.0.0.1:5001/barbapp-d4159/us-central1";
            } else {
                host = "https://us-central1-barbapp-d4159.cloudfunctions.net"
            }
            const res = await axios.post(host + "/setUpBusiness",
                {
                    "data": {
                        "email": businessEmailRef.current?.getInputText(),
                        "ownerID": id, //id is coming from 'userSelector'
                        "currency": businessCurrency.symbol,
                        "name": businessNameRef.current?.getInputText(),
                        "phone": businessPhoneNumber,
                        "country": searchParams.get('country') ? searchParams.get('country') : ""
                    }
                })

            posthog.capture("Created a business")

            dispatch.User.setBusinesses([res.data.businessID])
            dispatch.User.setOnboarding(true)
            setLoading(false)
        } catch (e) {
            console.log("Couldn't set up business. Error:", e);
            showToast("There was a server error. Please try again later.", "error");
            setLoading(false)
        }
    }

    const resetPassword = () => {
        const email = emailRef.current?.getInputText()
        // @ts-expect-error
        if (email.length === 0) {
            showToast(`Please enter an email and click on "I forgot my poassword" again`, "error");
            setResetError(true);
        } else {
            sendPasswordResetEmail(auth, email)
                .then(() => {
                    // Password reset email sent!
                    // ..
                    setReset(true)
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // ..
                });
        }
    }

    const [inviteData, setInviteData] = useState<any>(null);
    const [invitation, setInvitation] = useState<boolean>(false);
    const [invitationIgnored, setInvitationIgnored] = useState<boolean>(false);

    useEffect(() => {
        if (searchParams) {
            let token = searchParams.get('inviteToken');

            // console.log(token)

            client.post("/parseUserInvitation", {
                token,
            }).then((response: any) => {
                console.log("Invite", response.data)
                setInviteData(response.data);

            }).catch((e: any) => {

            });

        }
    }, [searchParams])

    const signIn = () => {
        setLoading(true)
        const email = emailRef.current?.getInputText()
        const password = passwordRef.current?.getInputText()

        // console.log(email, password)
        // @ts-expect-error
        signInWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                // Signed in 
                const user = userCredential.user;
                // ...

                const usersRef = doc(firestoreDb, `Users/${user.uid}`)
                const userDoc = await getDoc(usersRef)
                const userData = userDoc.data()
                analyticsEvents.login();
                posthog.capture("Sign in", {
                    $set: {
                        name: userData?.name,
                        email: email
                    }
                })



                if (userData?.businesses.length > 0) {
                    //if the user is onboarded, sign in
                    dispatch.User.saveUser(userDoc.data())
                    dispatch.Services.saveServices([])
                } else {
                    //if not onboarded yet, take them to the setting up of a business
                    dispatch.User.saveUser(userDoc.data())
                    const params = new URLSearchParams(searchParams)
                    params.set("step", "2");
                    params.set("join", "true");
                    setLoading(false)
                    setSearchParams(params)

                    console.log("sign in number", userData?.phone)

                    setBusinessPhoneNumber(userData?.phone ? userData.phone : "");
                    setIsBusinessPhoneValid(true);

                    showToast("Let's finish setting up your account!", "custom", 5000, undefined, "😃")
                }

                return user
            })
            .then((user) => {
                if (invitation) {
                    client.post("/acceptInvitation", {
                        "inviter": inviteData.inviter,
                        "inviteID": inviteData.id,
                        "businessID": inviteData.businessID,
                        "roles": inviteData.roles,
                        "userID": user.uid
                    })
                }
            })
            .then((response: any) => {
                if (invitation) {
                    showToast(`You have joined ${inviteData.businessName}!`, "success")
                }

            })
            .catch((error) => {
                setLoading(false)
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
                console.log(`Error code: ${errorCode}, error message: ${errorMessage}`)

                if (errorCode === "auth/network-request-failed") {
                    showToast("There's an issue with your internet connectivity. Please try again.", "error")
                }

                if (errorCode === "auth/email-already-in-use") {
                    showToast("Email already in use", "error")
                }
                if (errorCode === "auth/user-not-found") {
                    setUserNotFound(true)
                }
                if (errorCode === "auth/wrong-password") {
                    setInvalidPassword(true)
                }
                if (errorCode === "auth/too-many-requests") {
                    showToast("Too many unsuccessful log in attempts. Please try again later or reset your password.", "error")
                }
                if (errorCode === "auth/internal-error") {
                    showToast("There is an internal error. Please try again.", "error")
                }
                if (errorCode === "auth/invalid-email") {
                    setInvalidEmail(true)
                }


            });
    }

    const [ssoLoading, setSSOLoading] = useState<boolean>(false);

    const sso = () => {
        const email = emailRef.current?.getInputText()

        let accountDetails: Owner | Manager | Staff | any;



        signInWithPopup(auth, provider)
            .then(async (result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential?.accessToken;
                // The signed-in user info.
                const user = result.user;
                // IdP data available using getAdditionalUserInfo(result)

                const usersRef = doc(firestoreDb, `Users/${user.uid}`)
                const userDoc = await getDoc(usersRef)

                accountDetails = {
                    id: "",
                    businesses: [],
                    profilePic: "",
                    createdAt: new Date().toISOString(),
                    phone: phoneNumber,
                    name: user.displayName,
                    userName: user.displayName,
                    email: user.email,

                }


                const userData: any = {
                    ...accountDetails,
                    id: user.uid,
                }


                if (userDoc.exists()) {
                    // sign in
                    const userData = userDoc.data()
                    analyticsEvents.logout();
                    posthog.capture("Sign in", {
                        $set: {
                            name: userData?.name,
                            email: email
                        }
                    })

                    if (userData.businesses.length > 0) {
                        //if the user is onboarded, sign in
                        dispatch.User.saveUser(userDoc.data())
                        dispatch.Services.saveServices([])
                    } else {
                        //if not onboarded yet, take them to the setting up of a business
                        dispatch.User.saveUser(userDoc.data())

                        setBusinessEmail(userData?.email ? userData?.email : "");
                        const params = new URLSearchParams(searchParams)
                        params.set("step", "2");
                        params.set("join", "true");
                        setLoading(false)
                        setSearchParams(params)
                    }
                } else {
                    //sign up
                    setDoc(usersRef, userData)
                        .then(() => {

                            dispatch.User.saveUser(userData)
                            dispatch.Services.saveServices([])
                            // @ts-ignore
                            posthog.capture("Signup", {
                                Provider: "Google SSO",
                                Plan: searchParams.get('plan') ? searchParams.get('plan') : "",
                                $set: {
                                    name: accountDetails.name,
                                    email: email
                                }
                            })

                            const params = new URLSearchParams(searchParams)
                            params.set("step", "2");
                            setSearchParams(params)

                            setLoading(false)

                            if (process.env.NODE_ENV === "production") {
                                notifySlack(`New user signup: ${userData?.name} ${userData?.email} - ${userData?.phone} (Unique ID: ${user.uid})`,
                                    SIGNUP_SLACK_WEBHOOK_URL)
                            }

                        })
                }

                setSSOLoading(false);
                return user
                // ...
            })
            .then((user) => {
                if (invitation) {
                    client.post("/acceptInvitation", {
                        "inviter": inviteData.inviter,
                        "inviteID": inviteData.id,
                        "businessID": inviteData.businessID,
                        "roles": inviteData.roles,
                        "userID": user.uid
                    })
                }
            })
            .then((response: any) => {
                if (invitation) {
                    showToast(`You have joined ${inviteData.businessName}!`, "success")
                }

            })
            .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                console.log(`Error code: ${errorCode}, error message: ${errorMessage}`)
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    }

    /* -------------------------------------------------------------------------- */
    /*                         // Phone number validation                         */
    /* -------------------------------------------------------------------------- */
    const isValidInternationalPhoneNumber = (number: string, country: any) => {
        // Phones for test
        // +84967711901 (VN)
        // +441481244783 (GB)
        // +12133734253 (US)
        // +4915754449596 (DE)

        // Uncomment to choose best choice
        // 1.
        const phoneNumber = parsePhoneNumber(number, country);
        return phoneNumber && phoneNumber.isValid();
        // 2.
        // isPossiblePhoneNumber() only validates phone number length,
        // return isPossiblePhoneNumber(number, country);
        // 3.
        // while isValidPhoneNumber() validates both phone number length and the actual phone number digits.
        // return isValidPhoneNumber(number, country);
    };



    /* -------------------------------------------------------------------------- */
    /*                     // from IP address, get the country                    */
    /* -------------------------------------------------------------------------- */
    const getGeoInfo = () => {
        axios
            .get("https://ipapi.co/json/")
            .then((response) => {
                let data = response.data;
                // console.log(data);
                setCountry({
                    countryName: data.country_name,
                    countryCode: data.country_calling_code,
                });
                setCountryCode(data.country_code)
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getGeoInfo();
    }, []);

    /* -------------------------------------------------------------------------- */
    /*                              FORM VALIDATION                            */
    /* -------------------------------------------------------------------------- */

    const [validSignIn, setValidSignIn] = useState<any>();
    const [validSignUp, setValidSignUp] = useState<any>();
    const [validBusiness, setValidBusiness] = useState<any>();
    const validateSignIn = () => {
        return userEmail.length > 0 && userPassword.length > 5
    };
    const validateSignUp = () => {
        return userEmail.length > 0 && userPassword.length > 5 && userName.length > 0 && isUserPhoneValid
    };
    const validateBusiness = () => {
        return businessEmail?.length > 0 && businessName?.length > 0 && isBusinessPhoneValid
    };

    /* ------------------------ // form validator sign in ----------------------- */
    useEffect(() => {
        const isValidSignIn = validateSignIn();
        setValidSignIn(isValidSignIn);

    }, [userEmail, userPassword]);


    /* ------------------------ // form validator sign up ----------------------- */
    useEffect(() => {
        const isValidSignUp = validateSignUp();
        setValidSignUp(isValidSignUp);
    }, [userEmail, userPassword, userName, phoneNumber, isUserPhoneValid]);

    /* ----------------------- // form validator business ----------------------- */
    useEffect(() => {
        const isValidBusiness = validateBusiness();
        setValidBusiness(isValidBusiness);


    }, [businessName, businessEmail, isBusinessPhoneValid]);


    useEffect(() => {
        //   console.log(userEmail)
        setBusinessEmail(emailRef.current?.getInputText())

    }, [userEmail]);





    return (
        <div className="relative items-center justify-center md:flex md:h-screen md:w-full">

            <Toaster />


            <section className="text-gray-600 body-font h-screen w-full">
                {/* <div className="h-full mx-auto flex flex-col md:flex-row items-center p-6"> */}
                <div className="h-full mx-auto flex flex-col md:flex-row items-center">
                    {/* <div className=" md:w-1/2 md:h-full flex flex-col  rounded-3xl  md:p-6 xl:p-24 justify-center md:bg-gradient-to-r from-gray-900 to-slate-800 space-y-8"> */}
                    <div className=" md:w-1/2 md:h-full flex flex-col  justify-start md:bg-gradient-to-r from-gray-900 to-slate-800">

                        <div className=' w-full h-full flex items-center'>
                            <img alt="A screenshot of Wavier's interface" className='object-cover hidden md:block  w-full h-full' src={barbershop}></img>
                        </div>
                    </div>


                    <div className="w-full md:h-full md:w-1/2 px-4  mx-auto flex flex-col py-4 md:px-8 xl:px-16 justify-center items-center max-w-xl">
                        <div className='z-50 p-6'>
                            <div className='font-pacifico text-gray-900  text-xl md:text-2xl'>
                                Wavier
                            </div>
                        </div>
                        {((searchParams.get('inviteToken')) && !(searchParams.get('join') === "true")) &&
                            <>
                                {!inviteData ?
                                    <>
                                        <div className='flex items-center h-full w-full justify-center'>
                                            <Oval color='#ffffff' secondaryColor='#7D7D7D' width={24} />
                                        </div>
                                    </> :
                                    <>
                                        <div className="bg-white rounded-lg flex flex-col w-full">
                                            <h2 className="text-gray-900 text-3xl font-medium title-font ">{`Join ${inviteData.businessName}`}</h2>

                                            <div className="w-full flex flex-row gap-2 justify-center items-center">
                                                <img src={inviteData.logo} className="w-32" />
                                                <p>{`${inviteData.inviterName} is inviting you to join ${inviteData.businessName} with the following roles: 
                                            ${inviteData.roles.map((role: any) => role.toLowerCase().charAt(0).toUpperCase() + role.slice(1)).reduce((a: string, b: string) => `${a}, ${b}`)}
                                            `}</p>
                                            </div>


                                            {!invitationIgnored ?
                                                <>
                                                    {inviteData.requestStatus !== "already-done" ?
                                                        <>
                                                            <div className='mt-4 transition-all'>
                                                                <Button disabled={false} onClick={() => {
                                                                    setInvitation(true);
                                                                    navigate("/signin?join=true&step=1")
                                                                }} loading={loading} size={"sm"}>Accept invitation</Button>
                                                            </div>
                                                            <div className='mt-2'>
                                                                <Button disabled={false} secondaryGray onClick={() => {
                                                                    setInvitationIgnored(true);

                                                                    client.post("/rejectInvitation", {
                                                                        "inviter": inviteData.inviter,
                                                                        "inviteID": inviteData.id,
                                                                        "businessID": inviteData.businessID,
                                                                    }).then((response) => {

                                                                        setLoading(false);
                                                                    }).catch((e) => {
                                                                        console.log("Couldn't reject.", e)
                                                                        setLoading(false);
                                                                    });

                                                                }} size={"sm"}>Ignore</Button>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="w-full text-center">
                                                                This invitation is not valid anymore
                                                            </div>
                                                        </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    <div className="text-sm text-gray-500 w-full text-center">
                                                        You have ignored the invitation
                                                    </div>
                                                </>
                                            }


                                        </div>
                                    </>
                                }
                            </>

                        }

                        {(actions === AuthActions.SIGN_IN && !(searchParams.get('join') === "true")) && !searchParams.get('inviteToken') &&
                            <div className="bg-white rounded-lg flex flex-col w-full">
                                <h2 className="text-gray-900 text-3xl font-medium title-font ">Sign in</h2>
                                {invitation ?
                                    <p className='mb-5'>Sign in to your Wavier account and we'll add you to the business</p>
                                    :
                                    <p className='mb-5'>Enter your credentials to get access to your account</p>
                                }

                                <div className="relative mb-4">
                                    <TextInput type={"email"} error hint={userNotFound ? "No account registered with this email. Please Create an account." : invalidEmail ? "Invalid email" : resetError ? "Please enter your email and try again" : undefined}
                                        onChange={() => {
                                            setReset(false)
                                            setResetError(false)
                                            setUserNotFound(false)
                                            setUserEmail(emailRef.current?.getInputText())
                                            // setBusinessEmail(emailRef.current?.getInputText())
                                            // businessEmailRef.current?.setValue(emailRef.current?.getInputText());
                                            setInvalidEmail(false)
                                        }} ref={emailRef} label='Email address' />
                                </div>
                                <div className="relative mb-2">
                                    <TextInput onChange={() => {
                                        setInvalidPassword(false)
                                        setUserPassword(passwordRef.current?.getInputText())
                                    }} error hint={invalidPassword ? "Invalid password" : weakPassword ? "Password should be at leat 6 characters" : undefined} type={showPassword ? "text" : "password"} ref={passwordRef} label='Password' />
                                </div>
                                <div className="flex justify-between align-center">
                                    <div className="mb-4 text-sm flex align-center gap-2 text-gray-700 hover:text-gray-900"
                                        onClick={() => {

                                        }}>

                                        <input type="checkbox" checked={showPassword} onClick={() => { setShowPassword(show => !show) }} />

                                        <p>
                                            Show password
                                        </p>
                                    </div>
                                    <div className="mb-4 text-sm cursor-pointer text-gray-700 hover:text-gray-900 hover:underline"
                                        onClick={() => {
                                            resetPassword()
                                        }}>
                                        I forgot my password
                                    </div>
                                </div>
                                {reset &&
                                    <p className="text-sm text-gray-500">
                                        We've sent a password reset email to the email you entered above. Kindly reset your password and attempt to log in again.
                                    </p>
                                }
                                <div className='mt-4 transition-all'>
                                    <Button disabled={!validSignIn} onClick={() => {
                                        signIn()
                                    }} loading={loading} size={"sm"}>Log in</Button>
                                </div>
                                <div className='mt-2'>
                                    <Button secondaryGray disabled={false} onClick={() => {
                                        // setActions(AuthActions.SIGN_UP)
                                        navigate("/signin?join=true&step=1")
                                        // searchParams.set("join", "1")
                                    }} size={"sm"}>Create an account instead</Button>
                                </div>

                                <hr className="my-4" />
                                <div className="w-full flex justify-center items-center">
                                    <GoogleSSO type="signin" loading={ssoLoading} onClick={() => {
                                        setSSOLoading(true);
                                        sso();
                                    }} />
                                </div>

                            </div>
                        }

                        {(actions === AuthActions.SIGN_UP || searchParams.get('join') === "true") && searchParams.get('step') === "1" &&
                            <div className=" bg-white rounded-lg flex flex-col w-full">
                                {!invitation && <p className='mb-2 text-gray-500'>Step 1 out of 2</p>}
                                <h2 className="text-gray-900 text-3xl font-medium title-font ">Create an account</h2>
                                {invitation ?
                                    <p className='mb-5'>Create your Wavier account and we'll add you to the business</p>
                                    :
                                    <p className='mb-5'>Let's create your Wavier account. Tell us about you.</p>}
                                <div className="relative mb-2">
                                    <TextInput ref={nameRef} label='Full Name' onChange={() => {
                                        setUserName(nameRef.current?.getInputText())
                                    }} />
                                </div>

                                <div className="relative mb-2">
                                    <TextInput type={"email"} onChange={() => {
                                        setEmailAlreadyUsed(false)
                                        setUserEmail(emailRef.current?.getInputText())
                                        setInvalidEmail(false)
                                    }} error hint={emailAlreadyUsed ? "This email is already used. Please sign in instead." :
                                        invalidEmail ? "This email is not valid" : undefined} ref={emailRef} label='Email address' />
                                </div>
                                <div className="relative mb-2 ">
                                    <p className="mb-2 text-sm font-medium">
                                        Phone number
                                    </p>
                                    <div className="">
                                        <PhoneInput
                                            onChange={(value, country) => {
                                                setPhoneNumber(value)
                                                setBusinessPhoneNumber(value)

                                                // @ts-expect-error
                                                setCountryCode(country.countryCode.toUpperCase())
                                                // console.log("country", country.countryCode.toUpperCase())
                                                // console.log("value", value)
                                                // @ts-expect-error
                                                setIsUserPhoneValid(isValidInternationalPhoneNumber(value, country.countryCode.toUpperCase()))
                                                setIsBusinessPhoneValid(isValidInternationalPhoneNumber(value, country.countryCode.toUpperCase()))
                                                // console.log("Is valid for " + country.countryCode.toUpperCase(), isValidInternationalPhoneNumber(value, country.countryCode.toUpperCase()))
                                            }}
                                            placeholder="Phone number"
                                            specialLabel={''}
                                            country={countryCode.toLowerCase()}
                                            inputStyle={{
                                                width: '100%',
                                                height: '42px',
                                                borderRadius: '8px',
                                                fontFamily: 'Outfit'
                                            }}
                                            inputProps={{
                                                required: true
                                            }}
                                        />
                                    </div>
                                    {!isUserPhoneValid
                                        && <p className="text-xs text-error-500 mt-3">Please provide a valid number</p>
                                    }

                                </div>
                                <div>
                                    <div className="relative mb-2">
                                        <TextInput onChange={() => {
                                            setWeakPassword(false)
                                            setUserPassword(passwordRef.current?.getInputText())
                                        }} error hint={weakPassword ? "Password should be at least 6 characters" : undefined} type={showPassword ? "text" : "password"} ref={passwordRef} label='Password' />
                                    </div>
                                    {
                                        !(userPassword.length > 5) &&
                                        <p className="text-xs text-error-500 mb-2">Password should be at least 6 characters</p>
                                    }
                                    <div className="mb-4 text-sm flex align-center gap-2 text-gray-700 hover:text-gray-900"
                                        onClick={() => {

                                        }}>

                                        <input type="checkbox" checked={showPassword} onClick={() => { setShowPassword(show => !show) }} />

                                        <p>
                                            Show password
                                        </p>
                                    </div>
                                </div>

                                <div className='mt-4'>
                                    <Button disabled={!validSignUp} loading={loading} onClick={() => {
                                        // signUp(searchParams.get('plan') === "pro" ? "pro" : ""
                                        signUp()
                                    }} size={"sm"}>Next</Button>
                                </div>
                                <div className='mt-2'>
                                    <Button secondaryGray disabled={false} onClick={() => {
                                        // setActions(AuthActions.SIGN_IN)
                                        navigate("/signin")

                                    }} size={"sm"}>I already have an acoount</Button>
                                </div>
                                <hr className="my-4" />
                                <div className="w-full flex justify-center items-center">
                                    <GoogleSSO type="signup" loading={ssoLoading} onClick={() => {
                                        setSSOLoading(true);
                                        sso();
                                    }} />
                                </div>


                            </div>
                        }

                        {(searchParams.get('join') === "true") && searchParams.get('step') === "2" &&
                            <div className="bg-white rounded-lg flex flex-col md:ml-auto w-full mt-10 md:mt-0">
                                <p className='mb-2 text-gray-500'>Step 2 out of 2</p>
                                <h2 className="text-gray-900 text-3xl font-medium title-font ">Set up your business</h2>
                                <p className='mb-5'>Tell us a bit about your business</p>
                                {/* <p className='mb-5'>Want to add more than one business? Start by creating one now, and you can add more later</p> */}
                                <div className="relative mb-2">
                                    <TextInput ref={businessNameRef} label='Business Name' onChange={() => {
                                        setBusinessName(businessNameRef.current?.getInputText())
                                    }} />
                                </div>


                                <div className="relative mb-2">
                                    <TextInput value={businessEmail} type={"email"} onChange={() => {
                                        setEmailAlreadyUsed(false)
                                        setBusinessEmail(businessEmailRef.current?.getInputText())
                                    }} error hint={emailAlreadyUsed ? "This email is already used. Please sign in instead." : undefined} ref={businessEmailRef} label='Business Email address' />
                                </div>
                                <div className="relative mb-2 ">
                                    <p className="mb-2 text-sm font-medium">
                                        Business Phone number
                                    </p>
                                    <div className="">
                                        <PhoneInput
                                            onChange={(value, country) => {


                                                setBusinessPhoneNumber(value)
                                                // @ts-expect-error
                                                setBusinessCountryCode(country.countryCode.toUpperCase())
                                                // console.log("country", country.countryCode.toUpperCase())
                                                // console.log("value", value)
                                                // @ts-expect-error
                                                setIsBusinessPhoneValid(isValidInternationalPhoneNumber(value, country.countryCode.toUpperCase()))
                                                // console.log("business num Is valid for " + country.countryCode.toUpperCase(), isValidInternationalPhoneNumber(value, country.countryCode.toUpperCase()))
                                            }}
                                            value={businessPhoneNumber}
                                            placeholder="Business Phone number"
                                            specialLabel={''}
                                            country={countryCode.toLowerCase()}
                                            inputStyle={{
                                                width: '100%',
                                                height: '42px',
                                                borderRadius: '8px',
                                                fontFamily: 'Outfit'
                                            }}
                                        />
                                    </div>
                                    {!isBusinessPhoneValid
                                        && <p className="text-xs text-error-500 mt-3">Please provide a valid number</p>
                                    }
                                </div>
                                {/* <div className="relative mb-2">
                                    <TextInput onChange={() => setWeakPassword(false)} error hint={weakPassword ? "Password should be at least 6 characters" : undefined} type={"password"} ref={passwordRef} label='Password' />
                                </div> */}

                                {/* <div className='text-sm mt-2'>
                                    Please select if you're the business owner or an employee
                                </div> */}


                                {/* <p className="text-xs text-gray-500 mt-3">After you click on Create an account and if you're a shop owner, you'll be redirected to our Onboarding Form. Kidly fill that form and we'll get back to your for your onboarding!</p> */}
                                {
                                    !validBusiness &&

                                    <p className="text-xs text-error-500 mt-3">All fields are required</p>
                                }
                                <div className='mt-4'>
                                    <Button disabled={!validBusiness} loading={loading} onClick={() => {
                                        // signUp(searchParams.get('plan') === "pro" ? "pro" : "")

                                        setUpBusiness()
                                    }} size={"sm"}>Add my business</Button>
                                </div>
                                <div className='mt-2'>
                                    <Button secondaryGray disabled={false} onClick={() => {
                                        // setActions(AuthActions.SIGN_IN)
                                        navigate("/signin")

                                    }} size={"sm"}>I already have an account</Button>
                                </div>



                                {/* <p className="text-xs text-gray-500 mt-3">Literally you probably haven't heard of them jean shorts.</p> */}
                            </div>
                        }
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SignInPage