import Lottie from 'react-lottie'
import activityLoaderData from './splashLoader.json'

export const SplashLoader = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: activityLoaderData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    return (
        <div className="flex items-center h-screen">
            <Lottie options={defaultOptions} height={100} width={100} />
        </div>
    )
}
